import { createSlice } from "@reduxjs/toolkit";
import { SingleValue } from "react-select";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import {
    CreateAndTriggerWorkflowPayload,
    CreateNewWorkflowResponseData,
    IPostTestMailPayload,
    IResumeProjectWorkflowPayload,
    IRootState,
    IWorkflow,
    MODAL_STATES,
    Option,
    SetWorkflowDataPayload,
    WORKFLOW_MODES,
    WorkflowData,
} from "./workflow.types";

const initialState: IRootState = {
    workflowList: [],
    workflowName: "",
    projects: [],
    selectedWorkflow: null,
    workflowData: {
        date: new Date(),
        name: "",
        // comapnyName: "",
        // companyLink: "",
    },
    errors: {},
    modalStatus: "IDLE",
    workflowMode: "PERSONALIZED_WORKFLOW",
    saveWorkflowForLater: false,
    project: { label: "", value: "" },
};

const workflowReducers = {
    setWorkflowMode(state: any, { payload }: { payload: WORKFLOW_MODES }) {
        state.workflowMode = payload;
    },
    getWorkflowList(state: any) {},
    setWorkflowList(state: any, { payload }: { payload: any }) {
        state.workflowList = payload;
    },
    createNewWorkflow(state: any, { payload }: { payload: any }) {},
    createAndTriggerWorkflow(
        state: any,
        { payload }: { payload: CreateAndTriggerWorkflowPayload }
    ) {},
    deleteWorkflow(state: any, { payload }: { payload: any }) {},
    leaveWorkflow(state: any) {},
    changeWorkflowName(state: any, { payload }: { payload: string }) {
        if (state?.errors?.["workflowName"]) {
            delete state.errors["workflowName"];
        }
        state.workflowName = payload;
    },
    fetchProjects(state: any, { payload }: { payload: { action: string } }) {},
    setWorkflowProjects(state: any, { payload }: { payload: Option[] }) {
        state.projects = payload;
    },
    setSelectedWorkflow(
        state: any,
        { payload }: { payload: IWorkflow | null }
    ) {
        state.selectedWorkflow = payload;
        state.workflowData["name"] = payload?.name;
    },
    setWorkflowData(state: any, { payload }: { payload: WorkflowData }) {
        state.workflowData = payload;
    },
    setSingleWorkflowDataProperty(
        state: any,
        { payload: { key, value } }: { payload: SetWorkflowDataPayload }
    ) {
        if (key === "name" && state.errors["workflowName"]) {
            delete state.errors["workflowName"];
        }
        state.workflowData[key] = value;
    },
    validateWorkflowName(state: any) {
        if (!state.workflowData?.name) {
            state.errors["workflowName"] = "workflow name is required!";
        }
    },
    changeModalState(
        state: any,
        { payload }: { payload: "DEFAULT" | "LINEAR" | MODAL_STATES }
    ) {
        let status: MODAL_STATES | null =
            payload === "DEFAULT" ? null : state.modalStatus;

        if (payload !== "DEFAULT" && payload !== "LINEAR") {
            status = payload;
        } else {
            switch (status) {
                case "IDLE":
                    status = "SAVE_WORKFLOW_NAME";
                    break;
                case "SAVE_WORKFLOW_NAME":
                    status = "CREATED_WORKFLOW";
                    break;
                case "CREATED_WORKFLOW":
                    status = "OUTREACH_MODAL";
                    break;
                case "OUTREACH_MODAL":
                    status = "FINISHED";
                    break;
                default:
                    status = "IDLE";
                    break;
            }
        }
        state.modalStatus = status;
    },
    changeSaveWorkflowForLater(state: any, { payload }: { payload: boolean }) {
        state.saveWorkflowForLater = payload;
    },
    changeProject(state: any, { payload }: { payload: SingleValue<Option> }) {
        state.project = payload;
    },
    resetWorkflowState(state: any) {
        return initialState;
    },
    addToWorkflowList(
        state: any,
        { payload }: { payload: CreateNewWorkflowResponseData }
    ) {
        const isWorkflowAlreadyExists = state.workflowList.find(
            (workflow: IWorkflow) => {
                return workflow._id === payload._id;
            }
        );

        if (isWorkflowAlreadyExists) {
            state.workflowList = state.workflowList.map(
                (workflow: IWorkflow) => {
                    if (workflow._id === payload._id) {
                        return payload;
                    }

                    return workflow;
                }
            );
        } else {
            state.workflowList = state.workflowList.concat(
                payload as IWorkflow
            );
        }
    },
    resumeProjectWorkflow(
        state: any,
        { payload }: { payload: IResumeProjectWorkflowPayload & IActionPayload }
    ) {},
    postTestMail(
        state: any,
        { payload }: { payload: IPostTestMailPayload & IActionPayload }
    ) {},
};

export const workflowPage = createSlice({
    name: "workflow",
    initialState,
    reducers: workflowReducers,
});

export default workflowPage.reducer;
export const getWorkflowList = workflowPage.actions.getWorkflowList;
export const setWorkflowList = workflowPage.actions.setWorkflowList;
export const createNewWorkflow = workflowPage.actions.createNewWorkflow;
export const deleteWorkflow = workflowPage.actions.deleteWorkflow;
export const leaveWorkflow = workflowPage.actions.leaveWorkflow;
export const {
    changeWorkflowName,
    fetchProjects,
    setWorkflowProjects,
    setSelectedWorkflow,
    setWorkflowData,
    setSingleWorkflowDataProperty,
    validateWorkflowName,
    changeModalState,
    changeSaveWorkflowForLater,
    changeProject,
    resetWorkflowState,
    createAndTriggerWorkflow,
    addToWorkflowList,
    setWorkflowMode,
    resumeProjectWorkflow,
    postTestMail,
} = workflowPage.actions;

export const selectWorkflowList = (state: RootState) =>
    state.workflow.workflowList;

export const selectWorkflowName = (state: RootState) =>
    state.workflow.workflowName;

export const selectProjects = (state: RootState) => state.workflow.projects;

export const selectWorkflow = (state: RootState) =>
    state.workflow.selectedWorkflow;

export const selectWorkflowData = (state: RootState) =>
    state.workflow.workflowData;

export const selectWorkflowErrors = (state: RootState) => state.workflow.errors;
export const selectModalStatus = (state: RootState) =>
    state.workflow.modalStatus;

export const selectSaveWorkflowForLater = (state: RootState) =>
    state.workflow.saveWorkflowForLater;
export const selectWorkflowMode = (state: RootState) =>
    state.workflow.workflowMode;

export const selectProject = (state: RootState) => state.workflow.project;
