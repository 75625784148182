import { combineReducers } from "redux";

import InterActiveWalkthorugh from "../../components/InterActiveWalkthroughModal/interActiveWalkthorughModal.reducer";
import mailAlertReducers from "../../components/MailAlert/mailAlert.reducers";
import Notification from "../../components/Notification/index.reducer";
import NudgesReducer from "../../components/Nudges/Nudges.reducer";
import AppContainerReducer from "../../container/AppContainer/AppContainer.reducer";
import TourContainerReducer from "../../container/TourContainer/TourContainer.reducer";
import Signin from "../../pages/Signin/Signin.reducer";
import AllProjects from "../../pages/allProjects/index.reducer";
import passwordsReducer from "../../pages/forgotPassword/forgotPassword.reducer";
import integrationsReducer from "../../pages/integrations/integrations.reducer";
import teamsReducer from "../../pages/manageTeams/manageTeams.reducers";
import myAccountReducer from "../../pages/myAccount/myAccount.reducer";
import IProject from "../../pages/project/index.reducer";
import ExtTrackReducers from "../../pages/superAdmin/extensionTrack/extensionTrack.reducers";
import OrgList from "../../pages/superAdmin/orgList/orgList.reducers";
import SuperAdmin from "../../pages/superAdmin/superAdmin.reducer";
import UserList from "../../pages/superAdmin/userList/userList.reducers";
import Template from "../../pages/template/index.reducer";
import personalizedWorkflowReducer from "../../pages/triggerWorkflow/reducers/personalizedWorkflow.slice";
import unsubscribeEmailReducer from "../../pages/unsubscribe/unsubscribe.reducer";
import Workflow from "../../pages/workflow/index.reducer";
import loadersReducer from "./loaders.reducer";
import slackReducer from "./slackSend.reducer";
import HcUserList from "../../pages/HumanCircles/UserList/UserList.reducers";
import HcChatHistory from "../../pages/HumanCircles/ChatHistory/ChatHistory.reducer";

export default combineReducers({
    signin: Signin,
    allProjects: AllProjects,
    project: IProject,
    template: Template,
    workflow: Workflow,
    notification: Notification,
    teams: teamsReducer,
    loaders: loadersReducer,
    forgotPassword: passwordsReducer,
    appContainerReducer: AppContainerReducer,
    integrations: integrationsReducer,
    unsubscribeEmail: unsubscribeEmailReducer,
    orgList: OrgList,
    userList: UserList,
    tour: TourContainerReducer,
    myAccount: myAccountReducer,
    extensionTrack: ExtTrackReducers,
    interActiveWalkthorugh: InterActiveWalkthorugh,
    superAdmin: SuperAdmin,
    nudges: NudgesReducer,
    slackSlice: slackReducer,
    personalizedWorkflow: personalizedWorkflowReducer,
    mailAlert: mailAlertReducers,
    hcUserList: HcUserList,
    hcChatHistory: HcChatHistory,
});
