import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { IActionPayload } from "../../store/reducers/loaders.reducer";
import {
    IAuthLogin,
    IEmailSource,
    IReferralResponse,
    IRootState,
    ISignIn,
    ISignUp,
    IUserCredits,
    OnBoardModalStates,
    SendJobFunctionsResponse,
    SendReferralCodePayload,
    signinType,
} from "./Signin.types";

const initialState: IRootState = {
    user: {
        emailAuthorized: true,
        referralCode: "",
        sentInvite: "NOT_SENT",
        isSlackConnected: false,
    },
    isSignupClicked: false,
    fromExtension: false,
    fromInvitation: false,
    isTokenExpired: false,
    isRegisterApiCalled: false,
    userCredits: {
        currentCredits: 0,
        totalCredits: 0,
        remainingCredits: 0,
    },
    modalState: "IDLE",
    creditsReward: 0,
};

const reducers = {
    sendJobFunctions(
        state: typeof initialState,
        { payload }: { payload: SendJobFunctionsResponse }
    ) {},
    setFromExtension(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.fromExtension = payload;
    },
    setSignUpModalState(
        state: typeof initialState,
        { payload }: { payload: OnBoardModalStates }
    ) {
        state.modalState = payload;
    },
    setFromInvitation(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.fromInvitation = true;
    },
    googleLogin(
        state: typeof initialState,
        { payload }: { payload: string }
    ) {},
    signUp(
        state: typeof initialState,
        { payload }: { payload: IActionPayload & ISignUp }
    ) {
        state.isSignupClicked = true;
    },
    signIn(
        state: typeof initialState,
        { payload }: { payload: IActionPayload & ISignIn }
    ) {},
    signOut(state: typeof initialState) {
        document.cookie = `webAccessToken=''`;
        document.cookie = `_id=''`;
        document.cookie = `uuid=''`;
        document.cookie = `email=''`;
    },
    authLogin(
        state: typeof initialState,
        { payload }: { payload: IActionPayload & IAuthLogin }
    ) {},
    setUser(state: typeof initialState, { payload }: { payload: signinType }) {
        const { isSuperAdmin, emailAuthorized, _id, uuid, email } = payload;

        const updatedUser = {
            ...state.user,
            ...payload,
            emailAuthorized: isSuperAdmin ? true : emailAuthorized,
        };

        state.user = updatedUser;
        state.isRegisterApiCalled = false;

        document.cookie = `_id=${_id}`;
        document.cookie = `uuid=${uuid}`;
        document.cookie = `email=${email}`;
    },
    setIsRegisterApiCalled(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.isRegisterApiCalled = payload;
    },
    authorizeEmail(state: typeof initialState) {},
    authorizeOutlook(state: typeof initialState) {},
    updateGmail(
        state: typeof initialState,
        {
            payload,
        }: { payload: { authToken: string; emailSource: IEmailSource } }
    ) {},
    updateOutlook(
        state: typeof initialState,
        {
            payload,
        }: { payload: { authToken: string; emailSource: IEmailSource } }
    ) {},
    setIsTokenExpired(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.isTokenExpired = payload;
    },
    setIsSignupClicked(
        state: typeof initialState,
        { payload }: { payload: boolean }
    ) {
        state.isSignupClicked = payload;
    },
    updateUser(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                name: string;
                orgName: string;
                companyName: string;
                companyLink: string;
            } & IActionPayload;
        }
    ) {},
    leaveSignin(state: typeof initialState) {},
    sendReferralCode(
        state: typeof initialState,
        { payload }: { payload: SendReferralCodePayload }
    ) {},
    setSentInvite(
        state: typeof initialState,
        { payload }: { payload: IReferralResponse }
    ) {
        state.user.sentInvite = payload;
    },
    setUserCredits(
        state: typeof initialState,
        { payload }: { payload: IUserCredits }
    ) {
        state.userCredits.currentCredits = payload.currentCredits;
        state.userCredits.totalCredits = payload.totalCredits;
        state.userCredits.remainingCredits = payload.remainingCredits;
    },
    setCreditsReward(state: typeof initialState) {
        state.creditsReward += 1;
    },
    checkEmailForCreateUser(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                email: string;
                password: string;
                action: string;
            };
        }
    ) {},
    createUser(
        state: typeof initialState,
        {
            payload,
        }: {
            payload: {
                email: string;
                password?: string;
                action: string;
                uid?: string;
            };
        }
    ) {},
};

export const signinPage = createSlice({
    name: "signin",
    initialState,
    reducers,
});

export default signinPage.reducer;
export const {
    googleLogin,
    setUser,
    authLogin,
    authorizeEmail,
    updateGmail,
    signUp,
    signIn,
    signOut,
    leaveSignin,
    authorizeOutlook,
    updateOutlook,
    setFromExtension,
    setFromInvitation,
    setIsTokenExpired,
    sendReferralCode,
    setSentInvite,
    updateUser,
    setIsSignupClicked,
    setUserCredits,
    setIsRegisterApiCalled,
    setSignUpModalState,
    sendJobFunctions,
    setCreditsReward,
    createUser,
    checkEmailForCreateUser,
} = signinPage.actions;

export const selectUser = (state: RootState) => state.signin.user;
export const selectFromExtension = (state: RootState) =>
    state.signin.fromExtension;
export const selectFromInvitation = (state: RootState) =>
    state.signin.fromInvitation;
export const selectIsSignupClicked = (state: RootState) =>
    state.signin.isSignupClicked;
export const selectIsRegisterApiCalled = (state: RootState) =>
    state.signin.isRegisterApiCalled;
export const selectUserCredits = (state: RootState) => state.signin.userCredits;
export const selectSignupModalState = (state: RootState) =>
    state.signin.modalState;
export const selectTokenExpired = (state: RootState) =>
    state.signin.isTokenExpired;
export const selectCreditsReward = (state: RootState) =>
    state.signin.creditsReward;
