import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectCanShowInstallExtension } from "../../container/AppContainer/AppContainer.reducer";
import { openExtensionLink, openLinkedinRecruiter } from "../../utils/API";
import {
    HQ_EASYSOURCE_WEBSITE,
    HQ_EXTENSION_ON_BOARDING,
    HQ_OPEN_IN_NEW_PAGE_EXTENSION,
} from "../../utils/Constants";
import handleCleverTap from "../../utils/clevertap";
import ChromeExtension from "../ChromeExtension";
import SourceCandidatesMenu from "../SourceCandidatesMenu/SourceCandidatesMenu";

type ExtensionBtnProps = {
    variant?: "default" | "low";
};

export default function ExtensionBtn({
    variant = "default",
}: ExtensionBtnProps) {
    const location = useLocation();
    const isExtensionInstalled = useSelector(selectCanShowInstallExtension);

    const sendExtensionEvent = (url: string, type: string) => {
        handleCleverTap(`source candidates ${type}`);
        window.postMessage(
            {
                type:
                    url === openExtensionLink
                        ? HQ_OPEN_IN_NEW_PAGE_EXTENSION
                        : HQ_EXTENSION_ON_BOARDING,
                from: HQ_EASYSOURCE_WEBSITE,
                url,
            },
            "*"
        );
    };

    return (
        <>
            {isExtensionInstalled ? (
                <SourceCandidatesMenu
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    btnText="Source Candidates"
                    buttonStyle={{
                        opacity: location.pathname === "/workflows" ? 0.5 : 1,
                    }}
                >
                    <MenuItem
                        onClick={() =>
                            sendExtensionEvent(openExtensionLink, "Linkedin")
                        }
                    >
                        Search on LinkedIn
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            sendExtensionEvent(
                                openLinkedinRecruiter,
                                "Recruiter"
                            )
                        }
                    >
                        Search on LinkedIn Recruiter
                    </MenuItem>
                </SourceCandidatesMenu>
            ) : (
                <ChromeExtension variant={variant} />
            )}
        </>
    );
}
