import { useEffect, useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import { Card, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CustomTable from "../../../components/CustomTable/CustomTable";
import Navbar from "../../../components/Navbar";
import store from "../../../store";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { exportToCsvc, formatDate } from "../../../utils/helper";
import { setUserDetail } from "../superAdmin.reducer";
import styles from "./userList.module.scss";
import {
    getUserList,
    selectSortBy,
    selectTotalCandidatesScraped,
    selectTotalEmailsExtracted,
    selectTotalProjectsCreated,
    selectTotalUsers,
    selectTotalWorkflowsTriggered,
    selectUsers,
} from "./userList.reducers";

const columns = [
    {
        title: "User id",
        dataIndex: "_id",
        minWidth: 100,
    },
    {
        title: "Name",
        dataIndex: "name",
        minWidth: 100,
        render: (record: any) => {
            const linkedInProfileUrl =
                record?.liProfile || record?.userLiProfile?.profileUrl;

            const canShowLinkedin =
                linkedInProfileUrl && linkedInProfileUrl !== "-";

            return (
                <div className={styles.nameContainer}>
                    <Tooltip title="Click for super admin view">
                        <Link
                            onClick={() => {
                                store.dispatch(
                                    setUserDetail({
                                        displayName: record.name,
                                        email: record.email,
                                        id: parseInt(record._id),
                                    })
                                );
                            }}
                            to="/"
                            className={styles.link}
                        >
                            {record.name}
                        </Link>
                    </Tooltip>
                    {canShowLinkedin && (
                        <Tooltip title={linkedInProfileUrl}>
                            <a
                                className={styles.link}
                                href={linkedInProfileUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LinkedInIcon />
                            </a>
                        </Tooltip>
                    )}
                </div>
            );
        },
    },
    {
        title: "Email",
        dataIndex: "email",
    },
    {
        title: "Company",
        dataIndex: "companyName",
        render: (record: any) => {
            return (
                <Tooltip title={record.companyLink}>
                    <a
                        className={styles.link}
                        href={record.companyLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {record.companyName}
                    </a>
                </Tooltip>
            );
        },
    },
    {
        title: "Created On",
        dataIndex: "createdAt",
        render: (record: any) => {
            return formatDate(record.createdAt);
        },
    },
    {
        title: "Last login",
        dataIndex: "lastLogin",
        render: (record: any) => {
            return formatDate(record.lastLogin);
        },
    },
    {
        title: "Projects Created",
        dataIndex: "projectsCreated",
        minWidth: 100,
    },
    {
        title: "Candidates Scraped",
        dataIndex: "candidatesScraped",
        minWidth: 100,
    },
    {
        title: "Emails Extracted",
        dataIndex: "emailsExtracted",
        minWidth: 100,
    },
    {
        title: "Emails Not Found",
        dataIndex: "emailsFetched",
        minWidth: 100,
    },
    {
        title: "Workflows Triggered",
        dataIndex: "workflowsTriggered",
        minWidth: 100,
    },
    {
        title: "Required jobs",
        dataIndex: "requiredJobs",
        minWidth: 100,
        render: (record: any) => {
            const jobs = record?.requiredJobs?.toString();
            if (!jobs) return;
            return (
                <Tooltip title={jobs}>
                    <span>
                        {jobs.length < 20
                            ? jobs
                            : `${jobs.substring(0, 20)}...`}
                    </span>
                </Tooltip>
            );
        },
    },
];

const UserList = () => {
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const totalUsers = useSelector(selectTotalUsers);
    const totalProjectsCreated = useSelector(selectTotalProjectsCreated);
    const totalEmailsExtracted = useSelector(selectTotalEmailsExtracted);
    const totalWorkflowsTriggered = useSelector(selectTotalWorkflowsTriggered);
    const totalCandidatesScraped = useSelector(selectTotalCandidatesScraped);
    const isUsersLoading = useSelector(checkIfLoading(getUserList.type));
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const sortBy = useSelector(selectSortBy);

    useEffect(() => {
        dispatch(
            getUserList({
                start: currentPage - 1,
                limit: pageSize,
                action: getUserList.type,
            })
        );
    }, []);

    const onExportAllUsers = () => {
        dispatch(
            getUserList({
                start: 0,
                limit: totalUsers,
                action: getUserList.type,
                isExportAll: true,
            })
        );
    };

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        page: number
    ) => {
        setCurrentPage(page);
        dispatch(
            getUserList({
                start: page,
                limit: pageSize,
                action: getUserList.type,
            })
        );
    };

    const onChangePageSize = (pageSize: number) => {
        setCurrentPage(0);
        setPageSize(pageSize);
        dispatch(
            getUserList({
                start: 0,
                limit: pageSize,
                action: getUserList.type,
            })
        );
    };

    const onSortBy = (sortBy: string) => {
        setCurrentPage(0);
        dispatch(
            getUserList({
                start: 0,
                limit: pageSize,
                action: getUserList.type,
                sortBy,
            })
        );
    };

    return (
        <div className={styles.orgContainer}>
            <Navbar />
            <Row
                gutter={[16, 16]}
                style={{ marginBottom: 16 }}
                justify="center"
            >
                <Col span={4}>
                    <Card
                        title={
                            <Tooltip title="Total Users">
                                <span>Total Users</span>
                            </Tooltip>
                        }
                        size="small"
                    >
                        <span>{totalUsers}</span>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        title={
                            <Tooltip title="Total Projects Created">
                                <span>Total Projects Created</span>
                            </Tooltip>
                        }
                        size="small"
                    >
                        {totalProjectsCreated ?? 0}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        title={
                            <Tooltip title="Total Candidates Scraped">
                                <span>Total Candidates Scraped</span>
                            </Tooltip>
                        }
                        size="small"
                    >
                        {totalCandidatesScraped ?? 0}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        title={
                            <Tooltip title="Total Emails Extracted">
                                <span>Total Emails Extracted</span>
                            </Tooltip>
                        }
                        size="small"
                    >
                        {totalEmailsExtracted ?? 0}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        title={
                            <Tooltip title="Total Workflows Triggered">
                                <span>Total Workflows Triggered</span>
                            </Tooltip>
                        }
                        size="small"
                    >
                        {totalWorkflowsTriggered ?? 0}
                    </Card>
                </Col>
            </Row>
            <div className={styles.exportButtonContainer}>
                <Typography className={styles.userCount}>
                    Total users: {totalUsers}
                </Typography>
                <Button
                    className={styles.exportButton}
                    onClick={() => exportToCsvc(users)}
                >
                    Export users
                </Button>
                <Button
                    className={styles.exportButton}
                    onClick={onExportAllUsers}
                >
                    Export all users
                </Button>
                <FormControl className={styles.sortByContainer}>
                    <InputLabel id="SortBy">Sort By</InputLabel>
                    <Select
                        labelId="SortBy"
                        value={sortBy}
                        label="Sort By"
                        onChange={(e) => onSortBy(e.target.value)}
                    >
                        <MenuItem value="createdAt">Created on</MenuItem>
                        <MenuItem value="lastLogin">Last login</MenuItem>
                        <MenuItem value="name">Name</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="emailsExtracted">
                            Emails Extracted
                        </MenuItem>
                        <MenuItem value="emailsFetched">
                            Emails Fetched
                        </MenuItem>
                        <MenuItem value="workflowsTriggered">
                            Workflows Triggered
                        </MenuItem>
                        <MenuItem value="projectsCreated">
                            Projects Created
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <CustomTable
                columns={columns}
                total={totalUsers}
                rows={users}
                onPageChange={handlePageChange}
                page={currentPage}
                pageSize={pageSize}
                onPageSizeChange={(value) => onChangePageSize(value)}
                isLoading={isUsersLoading}
            />
        </div>
    );
};

export default UserList;
