import React, { useState } from "react";
import { Feedback } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CachedIcon from "@mui/icons-material/Cached";
import EmailIcon from "@mui/icons-material/Email";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PersonIcon from "@mui/icons-material/Person";
import { Fade, IconButton, Menu } from "@mui/material";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    authLogin,
    selectUser,
    signOut,
} from "../../../pages/Signin/Signin.reducer";
import { openExtensionLink } from "../../../utils/API";
import handleCleverTap from "../../../utils/clevertap";
import { nameInitials } from "../../../utils/helper";
import { setAuthorizeModal } from "../../MailAlert/mailAlert.reducers";
import style from "./index.module.scss";

type IAccountMenuProps = {
    setShowReferralModal: React.Dispatch<React.SetStateAction<boolean>>;
    startWalkthrough: () => void;
};

export default function AccountMenu({
    setShowReferralModal,
    startWalkthrough,
}: IAccountMenuProps) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const emailAuthorized = user.emailAuthorized;
    const openEl = Boolean(anchorEl);

    const showAuthorizeModal = () => {
        dispatch(setAuthorizeModal(true));
    };

    const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e.currentTarget == null) return;
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => setAnchorEl(null);

    const handleSignOut = () => {
        dispatch(signOut());
    };

    const onClickLinkedinAuthRefresh = (e: any) => {
        e.preventDefault();
        dispatch(authLogin({ action: authLogin.type, user }));
    };

    const isLiCookieAvailable = get(user, "liCookie");

    const clevertapEvent = (eventType: string) => {
        handleCleverTap(eventType);
    };

    return (
        <>
            <IconButton onClick={openMenu} size="small">
                <AccountCircleIcon
                    htmlColor="#C2CFE0"
                    sx={{
                        width: 30,
                        height: 30,
                    }}
                />
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                    className: "menuList",
                }}
                anchorEl={anchorEl}
                open={openEl}
                onClose={closeMenu}
                TransitionComponent={Fade}
                PaperProps={{
                    className: "menu-list-container",
                    style: {
                        minWidth: "350px",
                        borderRadius: "30px",
                    },
                }}
            >
                <div className={style["acc"]}>
                    <div className={style["acc__container"]}>
                        <div className={style["acc__userBox"]}>
                            <div className={style["acc__user"]}>
                                <h2>{user?.displayName}</h2>
                                <p>{user.email}</p>
                            </div>
                            {user.photoURL ? (
                                <img
                                    src={user.photoURL}
                                    alt="user profile icon"
                                    className={style["acc__iconBox"]}
                                />
                            ) : (
                                <p className={style["acc__avatar"]}>
                                    {nameInitials(
                                        user?.displayName
                                            ? user.displayName
                                            : "JD"
                                    )}
                                </p>
                            )}
                        </div>
                        <div className={style["acc__body"]}>
                            <Link
                                to="/my-account/details"
                                className={style["acc__item"]}
                            >
                                <PersonIcon
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        margin: "0 6px 0 1px",
                                        color: "rgb(51, 77, 110)",
                                    }}
                                />
                                <p>Account Details</p>
                            </Link>
                            <Link
                                to="/teams"
                                className={style["acc__item"]}
                                onClick={() =>
                                    clevertapEvent("Sidebar Manage Team")
                                }
                            >
                                <GroupsIcon
                                    sx={{
                                        width: 25,
                                        height: 25,
                                        marginRight: "6px",
                                        color: "rgb(51, 77, 110)",
                                    }}
                                />
                                <p>Manage Team</p>
                            </Link>
                            {!isLiCookieAvailable ? (
                                <a
                                    href={openExtensionLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={style["acc__item"]}
                                >
                                    <LinkedInIcon
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            marginRight: "6px",
                                            color: "rgb(51, 77, 110)",
                                        }}
                                    />
                                    <p>Authorize Linkedin</p>
                                    <IconButton
                                        onClick={onClickLinkedinAuthRefresh}
                                        sx={{ padding: "0 0 0 10px" }}
                                    >
                                        <CachedIcon />
                                    </IconButton>
                                </a>
                            ) : null}
                            {!emailAuthorized ? (
                                <div
                                    className={style["acc__item"]}
                                    onClick={showAuthorizeModal}
                                >
                                    <EmailIcon
                                        sx={{
                                            width: 22,
                                            height: 22,
                                            margin: "0 8px 0 1px",
                                            color: "rgb(51, 77, 110)",
                                        }}
                                    />
                                    <p>Authorize Mail</p>
                                </div>
                            ) : null}
                            <div
                                className={style["acc__item"]}
                                onClick={() => {
                                    closeMenu();
                                    setShowReferralModal((prev) => !prev);
                                }}
                            >
                                <GroupAddIcon
                                    sx={{
                                        width: 22,
                                        height: 22,
                                        margin: "0 8px 0 1px",
                                        color: "rgb(51, 77, 110)",
                                    }}
                                />
                                <p>Referrals</p>
                            </div>
                            <Link
                                to="https://forms.gle/PHMMSKnn98msnWGs9"
                                target={"_blank"}
                                className={style["acc__item"]}
                            >
                                <Feedback
                                    style={{
                                        color: "#334D6E",
                                        marginRight: 8,
                                        width: 22,
                                        height: 22,
                                    }}
                                />
                                <p>Share Feedback</p>
                            </Link>
                            {/* <div
                                className={style["acc__item"]}
                                onClick={handleSignOut}
                            >
                                <img src={logoutIconBlue} alt="" />
                                <p>Logout</p>
                            </div> */}
                        </div>
                        <div className={style["acc__footer"]}>
                            <p>write to us :</p>
                            <a href="mailto:easysource-support@hirequotient.com">
                                easysource-support@hirequotient.com
                            </a>
                        </div>
                    </div>
                </div>
            </Menu>
        </>
    );
}
