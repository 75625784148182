import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
    setErrorNotification,
    setSuccessNotification,
} from "../../components/Notification/index.reducer";
import { setNudges } from "../../components/Nudges/Nudges.reducer";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    setResumeWorkflow,
    setSelectAllCandidates,
} from "../project/index.reducer";
import {
    addToWorkflowList,
    changeModalState,
    createAndTriggerWorkflow,
    createNewWorkflow,
    deleteWorkflow,
    fetchProjects,
    getWorkflowList,
    leaveWorkflow,
    postTestMail,
    resumeProjectWorkflow,
    setSelectedWorkflow,
    setSingleWorkflowDataProperty,
    setWorkflowList,
    setWorkflowProjects,
    validateWorkflowName,
} from "./index.reducer";
import {
    CreateAndTriggerWorkflowPayload,
    CreateNewWorkflowResponse,
    FetchProjectsResponse,
    IPostTestMailPayload,
    IPostTestMailRequestPayload,
    IResumeProjectWorkflowPayload,
    Option,
} from "./workflow.types";

function* getWorkflowListSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().get, "/workflow/template/list");
        if (!response?.data) return;

        yield put(setWorkflowList(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* createNewWorkflowSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const { onSuccess, action, ...rest } = payload;
        yield put(startAction({ action }));

        // const state = yield select();
        // const comapnyName = get(state, "workflow.workflowData.comapnyName");
        // const companyLink = get(state, "workflow.workflowData.companyLink");
        // const companyVerified = get(state, "user.companyVerified");
        // if (!companyVerified) {
        //     rest.comapnyName = comapnyName;
        //     rest.companyLink = companyLink;
        // }
        const response: CreateNewWorkflowResponse = yield call(
            new API().post,
            "/workflow/template/create/",
            rest
        );

        if (!response) {
            yield put(setErrorNotification("Something went wrong..."));
            return;
        }

        yield put(setSelectedWorkflow(response.data));
        yield put(
            setSingleWorkflowDataProperty({
                key: "name",
                value: response.data.name,
            })
        );

        yield put(addToWorkflowList(response.data));

        if (onSuccess) {
            onSuccess(response);
        }

        handleCleverTap("Created Workflow Success", {
            rest,
        });

        yield put(changeModalState("LINEAR"));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteWorkflowSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(
            new API().delete,
            "/workflow/template/delete/" + payload
        );
        if (!response) return;

        yield put(getWorkflowList());
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* fetchProjectsSaga({
    payload,
}: {
    payload: { action: string };
}): SagaIterator {
    try {
        const { action } = payload;
        yield put(startAction({ action }));
        const response: FetchProjectsResponse = yield call(
            new API().get,
            "/v2/project/project-list"
        );

        const data: Option[] = response.data.map(({ _id, name }) => {
            return { value: String(_id), label: name };
        });

        yield put(setWorkflowProjects(data));
    } catch (err) {
        console.error("**allWorkflowsProjectSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* validateWorkflowNameSaga(): SagaIterator {
    const state = yield select();
    const errors = get(state, "workflow.errors");

    if (!errors["workflowName"]) {
        yield put(changeModalState("LINEAR"));
    }
}

function* createAndTriggerWorkflowSaga({
    payload: { createWorkflowPayload, addWorkflowPayload, action },
}: {
    payload: CreateAndTriggerWorkflowPayload;
}): SagaIterator {
    try {
        const { onSuccess, ...rest } = addWorkflowPayload;

        const state = yield select();
        const {
            workflowData: { comapnyName, companyLink },
            workflowMode,
        } = state.workflow;
        const companyVerified = get(state, "user.companyVerified");

        const { characterCount, toneOfVoice } = state.personalizedWorkflow;
        const selectAllCandidatesStateValue = state.project.selectAllCandidates;

        if (!companyVerified) {
            createWorkflowPayload.comapnyName = comapnyName;
            createWorkflowPayload.companyLink = companyLink;
        }

        yield put(startAction({ action }));

        const createWorkflowApiResponse: CreateNewWorkflowResponse = yield call(
            new API().post,
            "/workflow/template/create/",
            createWorkflowPayload
        );

        if (isEmpty(createWorkflowApiResponse.data)) return;

        const isPersonalized = workflowMode === "PERSONALIZED_WORKFLOW";
        const { _id: workflowTemplateId } = createWorkflowApiResponse.data;

        const triggerPayload = {
            ...rest,
            workflowTemplateId,
            hyperPersonalized: isPersonalized ? true : false,
            toneOfVoice: isPersonalized ? toneOfVoice : "",
            wordCount: isPersonalized ? characterCount : "",
        };

        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidate = [];
        }

        const triggerWorkflowApiResponse = yield call(
            new API().post,
            "/workflow/trigger",
            triggerPayload
        );

        const { installExtension, showEmail } = state.nudges;

        const updatedNudges = {
            installExtension,
            addToWorkflow: true,
            showEmail,
        };

        yield put(setNudges(updatedNudges));

        if (onSuccess) {
            const {
                workflowModel: { _id: workflowId },
                candidateCreditCount: workflowCredits,
            } = triggerWorkflowApiResponse.data;
            onSuccess({ workflowId, workflowCredits });
        }

        yield put(setSelectAllCandidates(false));
        yield put(changeModalState("FINISHED"));
        handleCleverTap("OutReach Started Successfully", {
            triggerPayload,
        });
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action }));
    }
}

function* resumeProjectWorkflowSaga({
    payload,
}: {
    payload: IResumeProjectWorkflowPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            `/workflow/trigger-pending/${payload.workflowId}`
        );
        if (!response) return;

        yield put(setResumeWorkflow(payload.workflowId));
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error("**resumeProjectWorkflowSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* postTestMailSaga({
    payload,
}: {
    payload: IPostTestMailPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const state = yield select();
        const hyperPersonalizedEmailSubject = get(
            state,
            "personalizedWorkflow.candidate.email.subject" || ""
        );

        let testMailPayload: IPostTestMailRequestPayload = {
            subject: hyperPersonalizedEmailSubject,
            body: payload.body,
            projectId: payload.projectId,
            candidateId: payload.candidateId,
        };
        if (payload.email) {
            testMailPayload.email = payload.email;
        }

        const response = yield call(
            new API().post,
            "/send-email/test",
            testMailPayload
        );
        if (!response) return;

        payload.onSuccess();
        yield put(setSuccessNotification(response.message));
    } catch (err) {
        console.error("**postTestMailSagaError=", err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(createNewWorkflow.type, createNewWorkflowSaga),
        // @ts-ignore
        yield takeLatest(getWorkflowList.type, getWorkflowListSaga),
        // @ts-ignore
        yield takeLatest(deleteWorkflow.type, deleteWorkflowSaga),
        // @ts-ignore
        yield takeLatest(fetchProjects.type, fetchProjectsSaga),
        // @ts-ignore
        yield takeLatest(validateWorkflowName.type, validateWorkflowNameSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            createAndTriggerWorkflow.type,
            createAndTriggerWorkflowSaga
        ),
        // @ts-ignore
        yield takeLatest(resumeProjectWorkflow.type, resumeProjectWorkflowSaga),
        // @ts-ignore
        yield takeLatest(postTestMail.type, postTestMailSaga),
    ];
    // @ts-ignore
    yield takeLatest(leaveWorkflow.type, CancelSagas, tasks);
}
