import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import { RootState } from "../../store";

const initialState = Object.freeze({
    projectList: [],
    project: {},
    notes: [],
    activities: [],
    candidateEmail: [],
    candidate: {},
    userEmail: [],
    showLoginLoader: true,
    canShowInstallExtension: false,
});

const reducers = {
    initiateSSE(
        state: any,
        {
            payload,
        }: {
            payload: {
                userId: string;
                token: string;
            };
        }
    ) {},
    setSSECandidate(state: any, { payload }: { payload: any }) {},
    setShowLoginLoader(state: any, { payload }: { payload: any }) {
        state.showLoginLoader = payload;
    },
    listenExtension(state: any) {},
    setCanShowInstallExtension(state: any, { payload }: { payload: any }) {
        state.canShowInstallExtension = payload;
    },
    doNotCallAction(state: any) {},
    resetSSE(state: any) {},
};

export const appContainerPage = createSlice({
    name: "appContainer",
    initialState,
    reducers,
});

export default appContainerPage.reducer;
export const {
    initiateSSE,
    setSSECandidate,
    setShowLoginLoader,
    listenExtension,
    setCanShowInstallExtension,
    doNotCallAction,
    resetSSE,
} = appContainerPage.actions;

export const selectShowLoginLoader = (state: RootState) =>
    get(state, "appContainerReducer.showLoginLoader");
export const selectCanShowInstallExtension = (state: RootState) =>
    get(state, "appContainerReducer.canShowInstallExtension");
