import { useState } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { Dropdown } from "antd";
import { MenuProps } from "rc-menu";
import { MenuInfo } from "rc-menu/lib/interface";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
    addToLoxoAgency,
    addToZohoRecruit,
} from "../../../integrations/integrations.reducer";
import { IIntegrations } from "../../../integrations/integrations.types";
import { selectSelectedCandidates } from "../../index.reducer";
import GreenhouseModal from "./greenhousePostCandidate";
import JobviteModal from "./jobvitePostCandidate";
import LeverModal from "./leverPostCandidate";

const integrationItems: MenuProps["items"] = [
    // {
    //     label: "Loxo",
    //     key: "loxo",
    // },
    {
        label: "Greenhouse",
        key: "greenhouse",
    },
    // {
    //     label: "Zoho Recruit",
    //     key: "zohoRecruit",
    // },
    // {
    //     label: "Lever",
    //     key: "lever",
    // },
    // {
    //     label: "Jobvite",
    //     key: "jobvite",
    // },
];

export default function ATSIntegration() {
    const params = useParams();
    const dispatch = useDispatch();
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const selectCount = selectedCandidates.length;
    const selectedCandidateIds = selectedCandidates.map(
        (item) => item.candidateId
    );

    const [openGreenhouseModal, setOpenGreenhouseModal] = useState(false);
    const [openLeverModal, setOpenLeverModal] = useState(false);
    const [openJobviteModal, setOpenJobviteModal] = useState(false);

    const handleAddAts = (key: IIntegrations) => {
        if (!selectCount || !params.id) return;

        switch (key) {
            case "loxo": {
                dispatch(
                    addToLoxoAgency({
                        projectId: Number(params.id),
                        candidateIds: selectedCandidateIds,
                        action: addToLoxoAgency.type,
                    })
                );
                break;
            }
            case "greenhouse": {
                setOpenGreenhouseModal(true);
                break;
            }
            case "zohoRecruit": {
                dispatch(
                    addToZohoRecruit({
                        projectId: Number(params.id),
                        candidateIds: selectedCandidateIds,
                        action: addToZohoRecruit.type,
                    })
                );
                break;
            }
            case "lever": {
                setOpenLeverModal(true);
                break;
            }
            case "jobvite": {
                setOpenJobviteModal(true);
                break;
            }
        }
    };

    return (
        <>
            <Dropdown
                trigger={["click"]}
                disabled={selectCount === 0}
                menu={{
                    items: integrationItems,
                    onClick: (e: MenuInfo) => {
                        e.domEvent.stopPropagation();
                        handleAddAts(e.key as IIntegrations);
                    },
                }}
            >
                <Button
                    variant="contained"
                    disabled={!selectCount}
                    sx={{
                        padding: "3px 16px",
                        fontWeight: 600,
                    }}
                >
                    Add to ATS
                    <CaretDownOutlined />
                </Button>
            </Dropdown>
            {params.id ? (
                <>
                    <GreenhouseModal
                        open={openGreenhouseModal}
                        onClose={() => setOpenGreenhouseModal(false)}
                        id={params.id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <LeverModal
                        open={openLeverModal}
                        onClose={() => setOpenLeverModal(false)}
                        id={params.id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                    <JobviteModal
                        open={openJobviteModal}
                        onClose={() => setOpenJobviteModal(false)}
                        id={params.id}
                        selectedCandidateIds={selectedCandidateIds}
                    />
                </>
            ) : null}
        </>
    );
}
