import { Button, Modal, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import styles from "./Signin.module.scss";
import { signOut } from "./Signin.reducer";

const TokenExpired = ({ open }: { open: boolean }) => {
    const dispatch = useDispatch();

    return (
        <Modal open={open}>
            <div className={styles.tokenExpired}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Session expired
                </Typography>
                <Typography id="modal-modal-description" mt={1} mb={2}>
                    Your session is expired please login again to continue.
                </Typography>
                <Button
                    variant="contained"
                    className={styles.tokenExpiredLoginButton}
                    onClick={() => dispatch(signOut())}
                >
                    Login
                </Button>
            </div>
        </Modal>
    );
};

export default TokenExpired;
