import { isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { ForkEffect, put, select, takeLatest } from "redux-saga/effects";

import { authLogin } from "../../pages/Signin/Signin.reducer";
import { getProject } from "../../pages/allProjects/index.reducer";
import { addSSECandidateDetails } from "../../pages/project/index.reducer";
import { ISSEEventPayload } from "../../pages/project/project.types";
import store from "../../store";
import {
    HQ_EASYSOURCE_EXTENSION,
    HQ_EXTENSION_TOKEN,
    LINKEDIN_PER_PROFILE_RESULT,
} from "../../utils/Constants";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import {
    doNotCallAction,
    listenExtension,
    resetSSE,
    setCanShowInstallExtension,
    setSSECandidate,
} from "./AppContainer.reducer";

function* setSSECandidateSaga({
    payload,
}: {
    payload: ISSEEventPayload;
}): SagaIterator {
    try {
        const state = yield select();
        const { _id: currentProjectId } = state.allProjects.project;
        const { event, projectStatus, processCompleted, projectId } = payload;
        const isStatusComplete = projectStatus === "COMPLETED";
        const isLiProfileResult = event === LINKEDIN_PER_PROFILE_RESULT;

        if (currentProjectId !== Number(projectId)) return;

        if (isStatusComplete || processCompleted) {
            yield put(
                getProject({
                    projectId: String(projectId),
                    action: getProject.type,
                })
            );
            return;
        }
        if (isLiProfileResult && !isEmpty(payload)) {
            yield put(addSSECandidateDetails(payload));
        }
    } catch (err: unknown) {
        console.error("***setSSECandidateSagaError=", err);
        handleException(err);
    }
}

/* eslint-disable */
function* listenExtensionSaga(): SagaIterator {
    try {
        window.addEventListener(
            "message",
            (event) => {
                const { from, type, data } = event.data;
                const isEventFromHqExtension = from === HQ_EASYSOURCE_EXTENSION;
                if (!isEventFromHqExtension) return;

                const isExtTokenEvent = type === HQ_EXTENSION_TOKEN;
                if (isExtTokenEvent) {
                    document.cookie = `accessToken=${data}`;
                    store.dispatch(setCanShowInstallExtension(true));
                    store.dispatch(
                        authLogin({
                            action: authLogin.type,
                            user: { name: "", email: "" },
                        })
                    );
                }
            },
            false
        );
    } catch (err: unknown) {
        console.error("***listenExtensionSagaError=", err);
        handleException(err);
    }
}
/* eslint-enable */

export default function* rootSagas(): Generator<ForkEffect> {
    const tasks = [
        // @ts-ignore
        yield takeLatest(setSSECandidate.type, setSSECandidateSaga),
        // @ts-ignore
        yield takeLatest(listenExtension.type, listenExtensionSaga),
    ];
    //@ts-ignore
    yield takeLatest(doNotCallAction.type, CancelSagas, tasks);
    // @ts-ignore
    yield takeLatest(resetSSE.type, CancelSagas, tasks);
}
