import { hyperEmail2Body, hyperEmailSubject } from "../constant";

export const simpleTemplate = [
    {
        isOpen: true,
        type: "email",
        subject: "Greetings for {companyName}",
        editorVal: `<p>Hi {candidateName},</p>
            <p><br></p>
            <p>I hope you are well and don't mind me reaching out. I saw your profile and believe you'd be an excellent fit for a {jobTitle}- role with us at <b>{companyName}</b>.</p>
            <p><br></p>
            <p>We are solving a huge problem and would love for you to be a part of this mission. You can know a little bit more about us by visiting <b>{companyLink}</b>.</p>
            <p><br></p>
            <p>Do let me know in case this is even remotely interesting. Would love to talk you through what we are building and give you a download on the role.</p>
            <p><br></p>
            <p>If you are open to discussing this opportunity, please let me know your ideal time for a chat.</p>
            <p><br></p>
            <p>Thanks,</p>
            <p>{senderName}</p>`,
    },
    {
        type: "delay",
        editorVal: "",
        subject: "",
        hours: "0",
        mins: "0",
        days: "2",
        weeks: "0",
    },
    {
        isOpen: true,
        type: "email",
        subject: "Your Chance to Make an Impact at {companyName}",
        editorVal: `<p>Hey {candidateName},</p>
            <p><br></p>
            <p>Circling back on my last email about a {role}. I think you could make a huge impact at <b>{companyName}</b> and I'd love to tell you more.</p>
            <p><br></p>
            <p>Interested? Looking forward to hearing from you. Even if you're not interested, do let me know.</p>
            <p><br></p>
            <p>Regards</p>
            <p>{senderName}</p>`,
    },
];

export const recruiterRecommendedTemplate = [
    {
        isOpen: true,
        type: "email",
        subject: "",
        editorVal: "",
    },
    {
        isOpen: true,
        type: "delay",
        editorVal: "",
        subject: "",
        hours: "0",
        mins: "5",
        days: "0",
        weeks: "0",
    },
    {
        isOpen: true,
        type: "connection-request",
        subject: "",
        editorVal: "",
    },
    {
        isOpen: true,
        type: "delay",
        editorVal: "",
        subject: "",
        hours: "0",
        mins: "0",
        days: "1",
        weeks: "0",
    },
    {
        isOpen: true,
        type: "email",
        subject: hyperEmailSubject,
        editorVal: hyperEmail2Body,
    },
];

export const multipleTouchpointsTemplate = [
    {
        isOpen: true,
        type: "email",
        subject: "Exciting Opportunity at {companyName}",
        editorVal: `
            <p>I am {senderName}, a Talent Acquisition Specialist at <b>{companyName}</b>.</p>
            <p><br></p>
            <p>We are looking for an experienced {jobTitle} to join our team. Your experience and skill set would be a great addition to the team.</p>
            <p><br></p>
            <p>We understand that you may have questions about the role and the company. Our colleagues have shared their experiences with us and we would be happy to answer any questions you may have.</p>
            <p><br></p>
            <p>If you are interested in this role, please get in touch with me. I look forward to hearing from you.</p>
            <p><br></p>
            <p>Best Regards,</p>
            <p>{senderName}</p>`,
    },
    {
        type: "delay",
        editorVal: "",
        subject: "",
        hours: "0",
        mins: "0",
        days: "2",
        weeks: "0",
    },
    {
        isOpen: true,
        type: "connection-request",
        subject: "Test Mail Subject",
        editorVal: `Dear {candidateName},\n\nI hope you're doing well. I came across your profile and was impressed by your experience. I would like to connect with you as I believe we could be a good fit for the role we are hiring for.\n\nIf you're open to it, I would love to connect and give you a brief on the role.\n\nThanks,\n{senderName}`,
    },
    {
        type: "delay",
        editorVal: "",
        subject: "",
        hours: "0",
        mins: "0",
        days: "2",
        weeks: "0",
    },
    {
        isOpen: true,
        type: "email",
        subject:
            "{candidateName}, Join the <b>{companyName}</b> Team and Help Power Up!",
        editorVal: `<p>Hey {candidateName},</p>
            <p><br></p>
            <p>Circling back on my last email about a {jobTitle}. I think you could make a huge impact at <b>{companyName}</b> and I'd love to tell you more.</p>
            <p><br></p>
            <p>Interested? Looking forward to hearing from you. Even if you're not interested, do let me know.</p>
            <p><br></p>
            <p>Regards</p>
            <p>{senderName}</p>`,
    },
    {
        type: "delay",
        editorVal: "",
        subject: "",
        hours: "0",
        mins: "0",
        days: "2",
        weeks: "0",
    },
    {
        isOpen: true,
        type: "in-mail",
        subject: "Greetings from {companyName}",
        editorVal: `<p>Hi {candidateName},</p>
            <p><br></p>
            <p>I hope you are well and don't mind me reaching out. I saw your profile and believe you'd be an excellent fit for a {jobTitle}- role with us at <b>{companyName}</b>.</p>
            <p><br></p>
            <p>We are solving a huge problem and would love for you to be a part of this mission. You can know a little bit more about us by visiting {companyLink}.</p>
            <p><br></p>
            <p>Do let me know in case this is even remotely interesting. Would love to talk you through what we are building and give you a download on the role.</p>
            <p><br></p>
            <p>If you are open to discussing this opportunity, please let me know your ideal time for a chat.</p>
            <p><br></p>
            <p>Thanks,</p>
            <p>{senderName}</p>`,
    },
];
