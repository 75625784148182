import { useState } from "react";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LayersIcon from "@mui/icons-material/Layers";
import LoopIcon from "@mui/icons-material/Loop";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
    Badge,
    IconButton,
    ListItemIcon,
    ListItemText,
    ListItemTextProps,
    Tooltip,
    TooltipProps,
    tooltipClasses,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    editGrayIcon,
    greyStacksIcon,
    infoLightBlueIcon,
    trashGrayMedium,
} from "../../../../constant";
import {
    deleteProject,
    selectCurrProject,
} from "../../../allProjects/index.reducer";
import { selectProjectWorkflows } from "../../index.reducer";
import EditModal from "../EditModal";
import DeleteModal from "../deleteModal";
import ProjectInfo from "../projectInfo/projectInfo";
import WorkflowModal from "../workflowModal";
import style from "./index.module.scss";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        width: 400,
        maxWidth: 400,
        border: "1px solid #dadde9",
    },
}));

const CustomListItemText = styled((props: ListItemTextProps) => (
    <ListItemText {...props} />
))(() => ({
    "& 	.MuiListItemText-primary": {
        fontSize: "14px",
    },
}));

type ProjectHeaderProps = {
    onClickRefreshIcon?: () => void;
    activeView?: string;
    handleListView?: () => void;
    handleGridView?: () => void;
};

type ProjectActionsMenuProps = {
    showWorkflowModal: () => void;
    showEditModal: () => void;
    showDeleteModal: () => void;
};

function ProjectActionsMenu({
    showDeleteModal,
    showEditModal,
    showWorkflowModal,
}: ProjectActionsMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon fontSize="small" htmlColor="#6c6c6c" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem
                    onClick={() => {
                        showWorkflowModal();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <LayersIcon fontSize="small" />
                    </ListItemIcon>
                    <CustomListItemText style={{ fontSize: 14 }}>
                        Associated Reachouts
                    </CustomListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        showEditModal();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <CreateIcon fontSize="small" />
                    </ListItemIcon>
                    <CustomListItemText>Edit Project Name</CustomListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        showDeleteModal();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <DeleteOutlineOutlined fontSize="small" />
                    </ListItemIcon>
                    <CustomListItemText>Delete</CustomListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}

export default function ProjectHeader({
    onClickRefreshIcon,
    activeView,
    handleListView,
    handleGridView,
}: ProjectHeaderProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectData = useSelector(selectCurrProject);
    const projectWorkflows = useSelector(selectProjectWorkflows);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);

    const isSSEOngoing =
        projectData?.projectStatus === "IN_PROGRESS" ||
        projectData?.projectStatus === "PENDING";
    const stoppedWorkflows = projectWorkflows.filter(
        (item) => item.status === "STOPPED"
    ).length;

    const showEditModal = () => setIsEditModalOpen(true);
    const closeEditModal = () => setIsEditModalOpen(false);

    const showWorkflowModal = () => setIsWorkflowModalOpen(true);
    const closeWorkflowModal = () => setIsWorkflowModalOpen(false);

    const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const handleDelete = () => {
        dispatch(deleteProject({ projectIds: [Number(projectData?._id)] }));
        navigate("/");
    };

    return (
        <>
            <div className={style["sp__header"]}>
                <div className={style["sp__header-left"]}>
                    <div className={style["sp__heading"]}>
                        <p>{projectData?.name}</p>
                        <div className={style["sp__headingBtnBox"]}>
                            <HtmlTooltip
                                title={<ProjectInfo />}
                                placement="bottom"
                            >
                                <IconButton>
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        htmlColor="#6c6c6c"
                                    />
                                </IconButton>
                            </HtmlTooltip>
                            <ProjectActionsMenu
                                showDeleteModal={showDeleteModal}
                                showEditModal={showEditModal}
                                showWorkflowModal={showWorkflowModal}
                            />
                        </div>
                    </div>
                    <div className={style["sp__headerWrapper"]}>
                        <p className={style["sp__createDate"]}>
                            Created on -&nbsp;
                            {dayjs(projectData?.createdAt).format("DD/MM/YYYY")}
                        </p>
                        {isSSEOngoing && onClickRefreshIcon ? (
                            <div className={style["sp__projectCreation"]}>
                                <p>
                                    Candidate retrieval in progress, please
                                    wait...
                                </p>
                                <IconButton onClick={onClickRefreshIcon}>
                                    <LoopIcon
                                        fontSize="small"
                                        sx={{
                                            animation:
                                                "spin 2s linear infinite",
                                            "@keyframes spin": {
                                                "0%": {
                                                    transform: "rotate(360deg)",
                                                },
                                                "100%": {
                                                    transform: "rotate(0deg)",
                                                },
                                            },
                                        }}
                                    />
                                </IconButton>
                            </div>
                        ) : null}
                    </div>
                </div>
                {/* <div className={style["sp__header-right"]}>
                    <div
                        className={`${
                            activeView === "list"
                                ? style["sp__viewTab-active"]
                                : ""
                        } ${style["sp__viewTab"]}`}
                        onClick={handleListView}
                    >
                        <FormatListBulletedIcon />
                        <p>List View</p>
                    </div>
                    <div
                        className={`${
                            activeView.includes("grid")
                                ? style["sp__viewTab-active"]
                                : ""
                        } ${style["sp__viewTab"]}`}
                        onClick={handleGridView}
                    >
                        <TableChartIcon />
                        <p>Grid View</p>
                    </div>
                </div> */}
            </div>
            <EditModal
                open={isEditModalOpen}
                onClose={closeEditModal}
                data={projectData}
            />
            <WorkflowModal
                open={isWorkflowModalOpen}
                onClose={closeWorkflowModal}
                projectId={projectData?._id as number}
            />
            <DeleteModal
                title="Are you sure you want to delete this project?"
                open={isDeleteModalOpen}
                onClose={closeDeleteModal}
                onSubmit={handleDelete}
            />
        </>
    );
}
