import { useCallback, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
// import SettingsIcon from "@mui/icons-material/Settings";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTour } from "@reactour/tour";
import dayjs from "dayjs";
import JSConfetti from "js-confetti";
import {
    get,
    has,
    isEmpty,
    isNil,
    isUndefined,
    filter as lodashFilter,
} from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import type {
    DraggableProvided,
    DropResult,
    DroppableProvided,
} from "react-beautiful-dnd/index";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import trophyGIF from "../../assets/img/trophy-gif.gif";
import ButtonTextWithLoading from "../../components/ButtonTextWithLoading";
import { MailAuthConnect } from "../../components/MailAuthModal";
import Modal from "../../components/Modal";
import Navbar from "../../components/Navbar";
import { setErrorNotification } from "../../components/Notification/index.reducer";
import InfoButton, {
    WorkflowInfo,
} from "../../components/SetupFlow/InfoButton";
import SetupFlow from "../../components/SetupFlow/SetupFlow";
import {
    CREATE_DASHBOARD_USER,
    googleSignInBtn,
    hyperEmail2Body,
    hyperEmail3Body,
    hyperEmailSubject,
} from "../../constant";
import { usePrompt } from "../../hooks/usePrompt";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import handleCleverTap from "../../utils/clevertap";
import { checkPlaceholdersFilled, removeHtmlTags } from "../../utils/helper";
import {
    multipleTouchpointsTemplate,
    recruiterRecommendedTemplate,
    simpleTemplate,
} from "../../utils/workflowTemplates";
import {
    authorizeEmail,
    authorizeOutlook,
    checkEmailForCreateUser,
    createUser,
    googleLogin,
    selectUser,
} from "../Signin/Signin.reducer";
import Delay from "../editWorkflow/components/delay";
import Email from "../editWorkflow/components/email";
import Recommendation from "../editWorkflow/components/recommendation";
import Reminder from "../editWorkflow/components/reminder";
import style from "../editWorkflow/index.module.scss";
import CustomMUIInput from "../project/components/CustomMUIInput";
import { addToWorkflow } from "../project/index.reducer";
import { getTemplateList, selectEditMsgPopup } from "../template/index.reducer";
import {
    changeModalState,
    changeSaveWorkflowForLater,
    createAndTriggerWorkflow,
    createNewWorkflow,
    getWorkflowList,
    resetWorkflowState,
    selectModalStatus,
    selectProject,
    selectSaveWorkflowForLater,
    selectWorkflow,
    selectWorkflowData,
    selectWorkflowErrors,
    selectWorkflowList,
    selectWorkflowMode,
    setSelectedWorkflow as setSelectedWorkflowAction,
    setSingleWorkflowDataProperty,
} from "../workflow/index.reducer";
import { IComponentList, IWorkflow } from "../workflow/workflow.types";
import BaseModal from "./components/BaseModal";
import FirstOccurence from "./components/FirstOccurence";
import {
    EmptyTemplatePlaceholder,
    HyperPersonalizationEditModal,
    PaneNavigation,
} from "./components/HyperPersonalizationPane";
import PersonalizationModal from "./components/PersonalizationModal";
import PersonalizedInputsModal from "./components/PersonalizedInputsModal";
import SampleProjectOutreachModal from "./components/SampleProjectOutreachModal";
import SelectProject from "./components/SelectProject";
import TourTriggerModal from "./components/tourTriggerModal";
import countOccurrences from "./helpers/countOccurences";
import generateSteps from "./helpers/generateSteps";
import hyperPersonaliseFollowEmails from "./helpers/hyperPersonaliseFollowEmails";
import {
    resetPersonalizedInputs,
    resetPersonalizedState,
    selectCandidate,
    selectCharacterCount,
    selectEditTemplatesModalState,
    selectToneOfVoice,
    setCandidateIndex,
    setEditTemplatesModal,
    submitPersonalizedInputs,
} from "./reducers/personalizedWorkflow.slice";

const emptySubjectMessages: { [key: string]: string } = {
    email: "Email subject cannot be empty",
    "in-mail": "Inmail subject cannot be empty",
};

const emptyBodyMessages: { [key: string]: string } = {
    email: "Email body cannot be empty",
    "in-mail": "Inmail body cannot be empty",
    "connection-request": "Connection Request body cannot be empty",
};

const emptySubjectPlaceholdersMessage: { [key: string]: string } = {
    email: "in email subject must be replaced",
    "in-mail": "in inmail subject must be replaced",
};

const emptyBodyPlaceholdersMessage: { [key: string]: string } = {
    email: "in email body must be replaced",
    "in-mail": "in inmail body must be replaced",
    "connection-request": "connection request body must be replaced",
};

type Args = {
    variant: "SUBJECT" | "BODY";
    text: string;
    type: string;
};

function createPlaceholderErrorMessage({ variant, text, type }: Args) {
    return `${text} ${
        variant === "SUBJECT"
            ? emptySubjectPlaceholdersMessage[type]
            : emptyBodyPlaceholdersMessage[type]
    }`;
}

function EmptySetupFlowMessage() {
    return (
        <Stack
            direction="row"
            style={{ position: "absolute", top: "50px", left: 0, right: 0 }}
            px={8}
            py={11}
            spacing={1}
            alignItems="center"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{ height: "50px", width: "80px", color: "#929292" }}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
            </svg>

            <Typography style={{ color: "#929292" }} variant="h5">
                Drag and drop elements from left panel to create workflow
            </Typography>
        </Stack>
    );
}

type validateWorkflowComponentsArgs = {
    componentList: IComponentList[];
    callback: (message: string) => void;
    ignoreCondition: boolean;
};

const getFilteredComponentList = (componentList: IComponentList[]) => {
    const filteredComponentList = lodashFilter(componentList, (element) => {
        return !isNil(element) && !isEmpty(element) && !isUndefined(element);
    });

    return filteredComponentList;
};

type CheckFirstComponentArgs = {
    componentList: IComponentList[];
    index: number;
    type: string;
};

// make a function that takes component and componentList
function checkFirstComponent({
    componentList,
    index,
    type: currentComponentType,
}: CheckFirstComponentArgs) {
    // find all components of given type, find their indexes, sort it, take first index, if it matches the given component then it's the first component
    const filteredComponentList = getFilteredComponentList(componentList);
    const slicedArray = filteredComponentList.slice(0, index);

    const isFirstComponent = slicedArray.reduce(
        (acc: boolean, curr: IComponentList, index: number) => {
            if (curr.type === currentComponentType) {
                acc = false;
            }

            return acc;
        },
        true
    );

    return isFirstComponent;
}

const isComponentsValid = ({
    componentList,
    callback,
    ignoreCondition,
}: validateWorkflowComponentsArgs) => {
    const filteredComponentList = getFilteredComponentList(componentList);

    if (!filteredComponentList.length) {
        callback("Kindly create a workflow to start the outreach");
        return false;
    }

    if (!ignoreCondition) {
        if (
            !filteredComponentList.some(
                (item) =>
                    item.type === "email" ||
                    item.type === "in-mail" ||
                    item.type === "connection-request" ||
                    item.type === "linked-in-msg"
            )
        ) {
            callback(`Workflow cannot be triggered by time delay alone`);
            return false;
        }

        if (
            filteredComponentList.some((item) => {
                return (
                    item.subject === "" ||
                    removeHtmlTags(item.editorVal) === "" ||
                    !checkPlaceholdersFilled(item.subject)?.value ||
                    !checkPlaceholdersFilled(item.editorVal)?.value
                );
            })
        ) {
            const emptySubjectType = filteredComponentList.find(
                (item) =>
                    item.subject === "" &&
                    item.type !== "connection-request" &&
                    item.type !== "delay"
            )?.type;

            if (emptySubjectType) {
                callback(emptySubjectMessages[emptySubjectType]);
                return false;
            }

            const emptyBodyType = filteredComponentList.find((item) => {
                const withoutHTMLTags = removeHtmlTags(item.editorVal);
                return (
                    item.type !== "delay" &&
                    (!withoutHTMLTags || withoutHTMLTags === "")
                );
            })?.type;

            if (emptyBodyType) {
                callback(emptyBodyMessages[emptyBodyType]);
                return false;
            }

            const placeholderNotFilledSubjectEle = filteredComponentList.find(
                (item) => {
                    return (
                        !checkPlaceholdersFilled(item.subject)?.value &&
                        item.type !== "delay"
                    );
                }
            );

            const placeholderNotFilledSubject = checkPlaceholdersFilled(
                placeholderNotFilledSubjectEle?.subject
            );

            const placeholderNotFilledSubjectString =
                placeholderNotFilledSubject?.matches?.join(", ");

            if (
                placeholderNotFilledSubjectString &&
                placeholderNotFilledSubjectEle?.type
            ) {
                const msg = createPlaceholderErrorMessage({
                    variant: "SUBJECT",
                    text: placeholderNotFilledSubjectString,
                    type: placeholderNotFilledSubjectEle?.type,
                });
                callback(msg);
                return false;
            }

            const placeholderNotFilledBodyEle = filteredComponentList.find(
                (item) => {
                    return (
                        !checkPlaceholdersFilled(item.editorVal)?.value &&
                        item.type !== "delay"
                    );
                }
            );

            const placeholderNotFilledBody = checkPlaceholdersFilled(
                placeholderNotFilledBodyEle?.editorVal
            );

            const placeholderNotFilledBodyString =
                placeholderNotFilledBody?.matches?.join(", ");

            if (
                placeholderNotFilledSubjectString &&
                placeholderNotFilledBodyEle?.type &&
                placeholderNotFilledBodyString
            ) {
                const msg = createPlaceholderErrorMessage({
                    variant: "BODY",
                    text: placeholderNotFilledBodyString,
                    type: placeholderNotFilledBodyEle?.type,
                });
                callback(msg);
                return false;
            }
        }
    }

    return true;
};

type CreditRewardModalProps = {
    open: boolean;
    onClose: () => void;
    workflowId: string;
    workflowCredits: number;
};

function CreditRewardModal({
    open,
    onClose,
    workflowId,
    workflowCredits,
}: CreditRewardModalProps) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const jsConfetti = new JSConfetti();
    const user = useSelector(selectUser);
    const uuid = get(user, "uuid");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const isSubmitPasswordLoading = useSelector(
        checkIfLoading(createUser.type)
    );

    const projectId = location.state?.projectId;

    useEffect(() => {
        jsConfetti.addConfetti();

        return () => jsConfetti.clearCanvas();
    }, []);

    const handleNavigate = () => {
        // const url = `/projects/${projectId}/statistics?workflow=${workflowId}`;
        const url = `/projects/${projectId}`;
        navigate(url, {
            replace: true,
            state: {
                fromWorkflowCreditsReward: true,
                workflowCreditReward: workflowCredits,
            },
        });
    };

    const onSubmitPassword = (e: any) => {
        e.preventDefault();
        dispatch(
            checkEmailForCreateUser({
                email,
                password,
                action: createUser.type,
            })
        );
        // handleNavigate();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle
                sx={{
                    color: "#747474",
                    fontSize: 24,
                    textAlign: "center",
                }}
            >
                Congratulations
            </DialogTitle>
            <DialogContent
                sx={{
                    color: "#747474",
                    textAlign: "center",
                }}
            >
                You have successfully reached out to candidates.
                {!uuid && (
                    <Stack width="100%">
                        <form onSubmit={onSubmitPassword}>
                            <br />
                            <TextField
                                sx={{
                                    marginBottom: "15px",
                                    width: " -webkit-fill-available",
                                }}
                                size="small"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                            />
                            <br />
                            <TextField
                                sx={{
                                    marginBottom: "15px",
                                    width: " -webkit-fill-available",
                                }}
                                size="small"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password"
                            />
                            <br />
                            <Button
                                disabled={isSubmitPasswordLoading}
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </form>
                        <img
                            src={googleSignInBtn}
                            alt="signin-with-google"
                            // className={styles["googleSignin"]}
                            style={{
                                width: "200px",
                                alignSelf: "center",
                                marginTop: "15px",
                            }}
                            onClick={() =>
                                dispatch(googleLogin(CREATE_DASHBOARD_USER))
                            }
                        />
                    </Stack>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: "center",
                }}
            >
                {uuid && (
                    <Button
                        variant="contained"
                        sx={{
                            background:
                                "linear-gradient(92.1deg, #E44E4E -2.91%, #FFE600 101%);",
                            border: "none",
                        }}
                        onClick={handleNavigate}
                    >
                        Thank You
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

function ValueLabel({ children }: { children: React.ReactNode }) {
    return (
        <Typography
            sx={{
                backgroundColor: "white",
                display: "inline-block",
                padding: "0.5rem 0.7rem",
                margin: "0 0.5rem",
                borderRadius: "0.7rem",
                textTransform: "capitalize",
                color: "black",
            }}
            fontSize={12}
        >
            {children}
        </Typography>
    );
}

function ToneOfVoiceAndWordCountLabel() {
    const toneOfVoice = useSelector(selectToneOfVoice);
    const characterCount = useSelector(selectCharacterCount);
    return (
        <Stack spacing={1} p={1}>
            <Typography variant="body2">
                Tone of voice:{" "}
                <ValueLabel>{toneOfVoice ? toneOfVoice : "-"}</ValueLabel>
            </Typography>
            <Typography variant="body2">
                Word Count:{" "}
                <ValueLabel>{characterCount ? characterCount : "-"}</ValueLabel>
            </Typography>
            <WorkflowInfo />
        </Stack>
    );
}

export default function TriggerWorkflow() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { currentStep } = useTour();

    const user = useSelector(selectUser);
    const selectedWorkflow = useSelector(selectWorkflow);
    const workflowList = useSelector(selectWorkflowList);
    const workflowMode = useSelector(selectWorkflowMode);
    const modalStatus = useSelector(selectModalStatus);
    const selectedProject = useSelector(selectProject);
    const saveWorkflowForLater = useSelector(selectSaveWorkflowForLater);
    const workflowData = useSelector(selectWorkflowData);
    const errors = useSelector(selectWorkflowErrors);
    const showEditTemplatesModal = useSelector(selectEditTemplatesModalState);
    const personalizedCandidate = useSelector(selectCandidate);
    const editPopupMsg = useSelector(selectEditMsgPopup);

    const isTemplateLoading = useSelector(
        checkIfLoading(submitPersonalizedInputs.type)
    );
    const isIndividualTemplatesLoading = useSelector(
        checkIfLoading(`${setCandidateIndex.type}_${personalizedCandidate?.id}`)
    );
    const isLoading = isTemplateLoading || isIndividualTemplatesLoading;

    const isCreateWorkflowLoading = useSelector(
        checkIfLoading(createNewWorkflow.type)
    );
    const isAddToWorkflowLoading = useSelector(
        checkIfLoading(addToWorkflow.type)
    );
    const isCreateAndTriggerWorkflowLoading = useSelector(
        checkIfLoading(createAndTriggerWorkflow.type)
    );
    const isCreateLoading =
        isCreateWorkflowLoading || isCreateAndTriggerWorkflowLoading;

    const [dragDropOverlay, setDragDropOverlay] = useState(false);
    const [overlayWorkflow, setOverlayWorkflow] = useState<boolean>(true);
    const [dirty, setDirty] = useState<boolean>(false);
    const [componentList, setComponentList] = useState<IComponentList[]>([]);
    const [addConfirmWorkflowModal, setAddConfirmWorkflowModal] = useState({
        workflowId: "",
        open: false,
    });
    const [creditRewardModal, setCreditRewardModal] = useState({
        workflowId: "",
        workflowCredits: 0,
        open: false,
    });
    const [openSampleOutreachModal, setOpenSampleOutreachModal] =
        useState(false);

    const userConnectionReqStats = user.workflowStats?.connReq;
    const userInmailStats = user.workflowStats?.inMail;
    const selectedCandidates = location.state?.candidates;
    const selectedProjectFromWorkflows = location?.state?.projectId;
    const isCandidatesSelected =
        Array.isArray(selectedCandidates) && selectedCandidates.length > 0;
    const candidate = useSelector(selectCandidate);
    const isTemplatesUnAvailable = candidate.fetchTemplateStatus === "ERROR";
    const setSelectedWorkflow = (data: IWorkflow | null) =>
        dispatch(setSelectedWorkflowAction(data));
    const handleNextModal = () => dispatch(changeModalState("LINEAR"));
    const closeModal = () => dispatch(changeModalState("CLOSE_MODAL"));

    usePrompt(
        "There are unsaved changes are you sure you want to proceed?",
        dirty
    );

    const isEmailComponentDisabled =
        countOccurrences({
            componentList,
            type: "email",
        }) === 3 && workflowMode === "PERSONALIZED_WORKFLOW";

    const isDisabledComponent = (type: string) => {
        const filteredComponentList = getFilteredComponentList(componentList);

        return (
            countOccurrences({
                componentList: filteredComponentList,
                type: type,
            }) === 1
        );
    };

    const showAddConfirmWorkflowModal = (workflowId: string) => {
        setAddConfirmWorkflowModal({
            open: true,
            workflowId,
        });
    };

    const closeAddConfirmWorkflowModal = () => {
        navigate("/", {
            state: {},
        });
    };

    const showCreditRewardModal = (
        workflowId: string,
        workflowCredits: number
    ) => {
        if (!workflowCredits) {
            showAddConfirmWorkflowModal(workflowId);
            return;
        }

        setCreditRewardModal({
            workflowId,
            workflowCredits,
            open: true,
        });
    };

    const closeCreditRewardModal = () => {
        setCreditRewardModal({
            workflowId: "",
            workflowCredits: 0,
            open: false,
        });
    };

    const showSelectWkModal = () => {
        const projectId = location.state?.projectId;
        if (projectId === "9999") {
            setOpenSampleOutreachModal(true);
            return;
        }

        const isValid = isComponentsValid({
            callback: (message: string) => {
                dispatch(setErrorNotification(message));
            },
            componentList,
            ignoreCondition: workflowMode === "PERSONALIZED_WORKFLOW",
        });

        if (!isValid) return;

        if (projectId) {
            handleCleverTap("Start outreach", {
                candidates: location?.state?.candidates?.toString(),
            });
        }

        handleNextModal();
    };

    useEffect(() => {
        if (isCandidatesSelected) {
            dispatch(changeModalState("PERSONALIZED_INPUTS"));
            setOverlayWorkflow(false);
        }
    }, [isCandidatesSelected]);

    useEffect(() => {
        if (!componentList.length && dragDropOverlay) {
            setDragDropOverlay(false);
        }
    }, [componentList.length, dragDropOverlay]);

    useEffect(() => {
        dispatch(getWorkflowList());
        dispatch(getTemplateList());
        setComponentList(recruiterRecommendedTemplate as IComponentList[]);

        return () => {
            dispatch(resetWorkflowState());
            dispatch(resetPersonalizedState());
            dispatch(resetPersonalizedInputs());
        };
    }, []);

    useEffect(() => {
        if (currentStep === 5) setOverlayWorkflow(false);
    }, [currentStep]);

    useEffect(() => {
        if (
            !isEmpty(selectedWorkflow) &&
            has(selectedWorkflow, "steps") &&
            !isEmpty(selectedWorkflow.steps)
        ) {
            const tmpList = [];
            for (let val of selectedWorkflow.steps) {
                let tmp;

                if (val.eventName === "Reminder") {
                    tmp = {
                        type: "rem",
                        editorVal: val.eventBody.body,
                    };
                } else if (val.eventName === "Delay") {
                    tmp = {
                        type: "delay",
                        weeks: val.eventBody.weeks,
                        days: val.eventBody.days,
                        hours: val.eventBody.hours,
                        mins: val.eventBody.mins,
                    };
                } else if (val.eventName === "Email") {
                    tmp = {
                        type: "email",
                        subject: val.eventBody.subject,
                        editorVal: val.eventBody.body,
                    };
                } else if (val.eventName === "connection-request") {
                    tmp = {
                        type: "connection-request",
                        editorVal: val.eventBody.body,
                    };
                } else if (val.eventName === "linked-in-msg") {
                    tmp = {
                        type: "linked-in-msg",
                        editorVal: val.eventBody.body,
                    };
                } else if (val.eventName === "in-mail") {
                    tmp = {
                        type: "in-mail",
                        subject: val.eventBody.subject,
                        editorVal: val.eventBody.body,
                    };
                }

                tmpList.push(tmp);
            }
            setComponentList(tmpList as IComponentList[]);
        }
    }, [selectedWorkflow]);

    useEffect(() => {
        handleSubjectChange(0, personalizedCandidate?.email?.subject || "");
        handleEditorValueChange(
            0,
            personalizedCandidate?.email?.body?.replaceAll("\n", "<br>") || ""
        );
        handleEditorValueChange(
            2,
            personalizedCandidate?.connectionReq?.body?.replaceAll(
                "\n",
                "<br>"
            ) || ""
        );
        handleSubjectChange(4, "Same as above email");
        handleEditorValueChange(
            4,
            personalizedCandidate?.followupEmail?.body?.replaceAll(
                "\n",
                "<br>"
            ) || ""
        );
    }, [personalizedCandidate]);

    const handleTimeChange = useCallback(
        (tarInd: number, key: string, newValue: string) => {
            const updatedComponentList = componentList.map(
                (component: any, index: number) => {
                    if (index === tarInd) {
                        return {
                            ...component,
                            [key]: Number(newValue) < 0 ? "0" : newValue,
                        };
                    }
                    return component;
                }
            );
            setComponentList(updatedComponentList);
        },
        [componentList]
    );

    const handleSubjectChange = useCallback(
        (tarInd: number, newValue: string) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr[tarInd] = { ...tmpArr[tarInd], subject: newValue };
                return tmpArr;
            });
        },
        [componentList]
    );

    const handleEditorValueChange = useCallback(
        (tarInd: number, newValue: string) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr[tarInd] = { ...tmpArr[tarInd], editorVal: newValue };
                return tmpArr;
            });
        },
        [componentList]
    );

    const handleCollapseChange = useCallback(
        (tarInd: number) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr[tarInd] = {
                    ...tmpArr[tarInd],
                    isOpen: !tmpArr[tarInd].isOpen,
                };
                return tmpArr;
            });
        },
        [componentList]
    );

    const handleRemove = useCallback(
        (tarInd: number) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr.splice(tarInd, 1);

                if (workflowMode === "PERSONALIZED_WORKFLOW") {
                    const updatedArr = hyperPersonaliseFollowEmails({
                        componentList: tmpArr,
                    });
                    return updatedArr;
                }

                return tmpArr;
            });
        },
        [componentList]
    );

    const handleDuplicate = useCallback(
        (tarInd: number) => {
            setComponentList((prevList) => {
                const tmpArr = [...prevList];
                tmpArr.splice(tarInd, 0, { ...tmpArr[tarInd] });

                if (workflowMode === "PERSONALIZED_WORKFLOW") {
                    const updatedArr = hyperPersonaliseFollowEmails({
                        componentList: tmpArr,
                    });
                    return updatedArr;
                }

                return tmpArr;
            });
        },
        [componentList]
    );

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;

            const { source, destination } = result;
            const isSrcComponent = source.droppableId === "col-1";
            const isDstComponent = destination.droppableId === "col-2";

            if (!isSrcComponent || !isDstComponent) return;

            if (destination.index !== source.index) setDirty(true);

            let tmpList = [...componentList];
            tmpList.splice(destination.index, 0, {
                isOpen: true,
                type: result.draggableId,
                editorVal: "",
                subject: "",
                hours: "0",
                mins: "5",
                days: "0",
                weeks: "0",
            });
            const emailOccurences = countOccurrences({
                componentList: tmpList,
                type: "email",
            });
            if (workflowMode === "PERSONALIZED_WORKFLOW") {
                tmpList[destination.index].subject =
                    emailOccurences === 2 || emailOccurences === 3
                        ? hyperEmailSubject
                        : "";
                tmpList[destination.index].editorVal =
                    emailOccurences === 2
                        ? hyperEmail2Body
                        : emailOccurences === 3
                        ? hyperEmail3Body
                        : "";
            }
            setComponentList(tmpList);
        },
        [componentList]
    );

    const handleDefaultWorkflow = (
        type: "SIMPLE" | "RECRUITER" | "MUTLIPLE"
    ) => {
        handleCleverTap("Select sample workflow", {
            type: type,
        });

        setOverlayWorkflow(false);
        if (type === "SIMPLE")
            setComponentList(simpleTemplate as IComponentList[]);
        else if (type === "RECRUITER")
            setComponentList(recruiterRecommendedTemplate as IComponentList[]);
        else setComponentList(multipleTouchpointsTemplate as IComponentList[]);
    };

    const handleOutReachStart = () => {
        const filteredComponentList = getFilteredComponentList(componentList);
        if (
            !user.emailAuthorized &&
            filteredComponentList.some((item) => item.type === "email")
        ) {
            dispatch(setErrorNotification("Please authorize your email first"));
            return;
        }

        if (modalStatus === "OUTREACH_MODAL" && !selectedProject?.value) {
            dispatch(setErrorNotification("Please select project"));
            return;
        }

        if (!selectedWorkflow?._id) {
            return;
        }

        setDirty(false);

        const project = selectedProject?.value
            ? Number(selectedProject.value)
            : Number(location?.state?.projectId);

        const sendOutReachToAllCandidates = selectedProject?.value
            ? true
            : false;

        const addToWorkflowPayload = {
            workflowTemplateId: selectedWorkflow?._id,
            scheduledFor: dayjs(workflowData.date).toISOString(),
            project,
            candidate: selectedCandidates,
            onSuccess: ({
                workflowId,
                workflowCredits,
            }: {
                workflowId: string;
                workflowCredits: number;
            }) => showCreditRewardModal(workflowId, workflowCredits),
            action: addToWorkflow.type,
        };

        if (location.state?.projectId) {
            handleCleverTap("Confirm outreach", {
                name: selectedWorkflow?.name || workflowData.name,
                workflowTemplateId: selectedWorkflow?._id,
                scheduledFor: dayjs(workflowData.date).toISOString(),
                project,
                candidate: location?.state?.candidates?.toString(),
            });
        }

        dispatch(
            addToWorkflow({
                ...addToWorkflowPayload,
                selectAll: sendOutReachToAllCandidates ? true : false,
            })
        );
    };

    const handleWorkflowCreate = () => {
        // if candidates are selected then directly call trigger modal
        const filteredComponentList = getFilteredComponentList(componentList);

        // replace subject of follow-up email with hyperpersonalised email subject
        filteredComponentList[4].subject = filteredComponentList[0].subject;

        const steps = generateSteps(filteredComponentList);
        const projectId = location?.state?.projectId;

        // if (!(selectedWorkflow?.name || workflowData.name)) {
        //     dispatch(setErrorNotification("Please enter workflow name"));
        //     return;
        // }

        const workflowTempData = {
            name: workflowData.name,
            steps: steps,
            completed: saveWorkflowForLater,
        };

        setDirty(false);

        if (isCandidatesSelected) {
            if (selectedWorkflow && selectedWorkflow.name) {
                handleOutReachStart();
                return;
            }

            if (location.state?.projectId) {
                handleCleverTap("Confirm outreach", {
                    name: selectedWorkflow?.name || workflowData.name,
                    workflowTemplateId: selectedWorkflow?._id,
                    scheduledFor: dayjs(workflowData.date).toISOString(),
                    project: projectId,
                    candidate: location?.state?.candidates?.toString(),
                });
            }
            dispatch(
                createAndTriggerWorkflow({
                    createWorkflowPayload: workflowTempData,
                    addWorkflowPayload: {
                        scheduledFor: dayjs(workflowData.date).toISOString(),
                        project: projectId,
                        candidate: selectedCandidates,
                        onSuccess: ({ workflowId, workflowCredits }) =>
                            showCreditRewardModal(workflowId, workflowCredits),
                    },
                    action: createAndTriggerWorkflow.type,
                })
            );
            return;
        }

        if (selectedWorkflow?._id) {
            dispatch(
                createNewWorkflow({
                    ...workflowTempData,
                    _id: selectedWorkflow._id,
                    action: createNewWorkflow.type,
                })
            );
            return;
        }

        dispatch(
            createNewWorkflow({
                ...workflowTempData,
                action: createNewWorkflow.type,
            })
        );

        // workflow is saved, don't need to give prompt
    };

    const onClickCreateNewWorkflow = () => {
        handleCleverTap("Create new workflow", {});
        // clear personalized state
        dispatch(resetPersonalizedState());
        // if (isCandidatesSelected) {
        //     dispatch(changeModalState("PERSONALIZED_MODAL"));
        // }
        setOverlayWorkflow(false);
    };

    const handleModalOpen = () => dispatch(setEditTemplatesModal(true));

    const handleEmailClick = (type: "gmail" | "outlook") => {
        if (type === "gmail") dispatch(authorizeEmail());
        else dispatch(authorizeOutlook());
    };

    const showDragMessage =
        componentList.length === 0 && !overlayWorkflow && !dragDropOverlay;

    const title = isCandidatesSelected
        ? "Start Outreach"
        : selectedWorkflow?.name
        ? "Edit Workflow"
        : "Create Workflow";

    const createBtnText = isCandidatesSelected
        ? "Start"
        : selectedWorkflow?.name
        ? "Update"
        : "Create";

    return (
        <div className={style["wk"]}>
            <div className={style["wk__container"]}>
                <Navbar
                    containerStyle={{
                        padding: "10px 10px 0",
                    }}
                />
                <div className={style["wk__header"]}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Typography fontSize={18} fontWeight={600}>
                            Workflow: Hyper-personalized Outreach
                        </Typography>
                        <Tooltip
                            title={<ToneOfVoiceAndWordCountLabel />}
                            placement="right"
                        >
                            <IconButton>
                                <InfoIcon
                                    fontSize="medium"
                                    style={{ color: "rgba(0, 0, 0, 0.3)" }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Stack alignItems="flex-end" spacing={0.5}>
                        <PaneNavigation variant="DEFAULT" />
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <IconButton
                                size="small"
                                sx={{
                                    color: editPopupMsg ? "red" : "inherit",
                                    border: editPopupMsg
                                        ? "1px solid #d9d9d9"
                                        : "initial",
                                    boxShadow: editPopupMsg
                                        ? "rgba(16, 156, 241, 0.24) 0px 4px 10px 0px"
                                        : "initial",
                                }}
                                onClick={handleModalOpen}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <Typography
                                fontSize={16}
                                fontWeight={editPopupMsg ? "700" : "initial"}
                                color={editPopupMsg ? "red" : "inherit"}
                            >
                                Edit personalized messages
                            </Typography>
                        </Stack>
                    </Stack>
                    {/* <div className={style["wk__headerLeft"]}>
                        <p className={style["wk__heading"]}>
                            {selectedWorkflow?.name}
                        </p>
                        {selectedWorkflow?.createdAt && (
                            <p className={style["wk__createDate"]}>
                                {new Date(
                                    selectedWorkflow.createdAt
                                ).toLocaleDateString()}
                            </p>
                        )}
                    </div> */}
                    {/* <div className={style["wk__headerRight"]}>
                        <Tooltip
                            title={<ToneOfVoiceAndWordCountLabel />}
                            placement="left"
                        >
                            <InfoIcon
                                fontSize="medium"
                                style={{ color: "rgba(0, 0, 0, 0.3)" }}
                            />
                        </Tooltip>
                    </div> */}
                </div>
                <DragDropContext
                    onDragEnd={(res: any) => {
                        onDragEnd(res);
                        setDragDropOverlay(false);
                    }}
                    onDragStart={() => setDragDropOverlay(true)}
                >
                    <div className={style["wk__body"]}>
                        {/* <div
                            className={style["wk__overlay"]}
                            style={{
                                display: overlayWorkflow ? "block" : "none",
                            }}
                        >
                            <div className={style["wk__overlayBody"]}>
                                <p className={style["wk__overlayTitle"]}>
                                    Select from the best performing workflows,
                                    or create your own workflow{" "}
                                </p>
                                <div className={style["wk__overlayWrapper"]}>
                                    <div
                                        className={
                                            style["wk__overlayWrapperCard"]
                                        }
                                        onClick={() =>
                                            handleDefaultWorkflow("SIMPLE")
                                        }
                                    >
                                        <p>
                                            Simple
                                            <br />
                                            workflow
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            style["wk__overlayWrapperCard"]
                                        }
                                        onClick={() =>
                                            handleDefaultWorkflow("RECRUITER")
                                        }
                                    >
                                        <p>
                                            Recruiter
                                            <br />
                                            recommended
                                            <br />
                                            workflow
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            style["wk__overlayWrapperCard"]
                                        }
                                        onClick={() =>
                                            handleDefaultWorkflow("MUTLIPLE")
                                        }
                                    >
                                        <p>
                                            Multiple touchpoint
                                            <br />
                                            workflow
                                        </p>
                                    </div>
                                </div>
                                <Button
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                    sx={{ marginTop: "3rem" }}
                                    onClick={onClickCreateNewWorkflow}
                                >
                                    Create a new workflow
                                </Button>
                            </div>
                        </div> */}
                        {/* <div className={style["wk__sidebar"]} id="wkSidebar">
                            <Droppable droppableId="col-1">
                                {(dropProvider: DroppableProvided) => (
                                    <div
                                        className={style["wk__sidebarScroll"]}
                                        ref={dropProvider.innerRef}
                                        {...dropProvider.droppableProps}
                                    >
                                        <div
                                            className={`${
                                                selectedWorkflow &&
                                                location.state?.candidates
                                                    ? style["wk__item-inactive"]
                                                    : ""
                                            } ${style["wk__item"]}`}
                                        >
                                            <p
                                                className={
                                                    style["wk__itemHead"]
                                                }
                                            >
                                                Outreach
                                            </p>
                                            <Draggable
                                                key={"email"}
                                                draggableId="email"
                                                index={1}
                                                isDragDisabled={
                                                    isEmailComponentDisabled
                                                }
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={`${
                                                            isEmailComponentDisabled
                                                                ? style[
                                                                      "wk__itemLink-disabled"
                                                                  ]
                                                                : style[
                                                                      "wk__itemLink"
                                                                  ]
                                                        }
                                                    `}
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Email
                                                    </p>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                draggableId="rem"
                                                index={3}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Call reminder
                                                    </p>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"connection-request"}
                                                draggableId="connection-request"
                                                index={5}
                                                isDragDisabled={isDisabledComponent(
                                                    "connection-request"
                                                )}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <Tooltip
                                                        title={
                                                            isDisabledComponent(
                                                                "connection-request"
                                                            )
                                                                ? "Connection request can only be sent once"
                                                                : null
                                                        }
                                                    >
                                                        <p
                                                            className={`${
                                                                isDisabledComponent(
                                                                    "connection-request"
                                                                )
                                                                    ? style[
                                                                          "wk__itemLink-disabled"
                                                                      ]
                                                                    : style[
                                                                          "wk__itemLink"
                                                                      ]
                                                            }
                                                    `}
                                                            ref={
                                                                dragProvider.innerRef
                                                            }
                                                            {...dragProvider.dragHandleProps}
                                                            {...dragProvider.draggableProps}
                                                        >
                                                            LinkedIn Connection
                                                            Req.
                                                        </p>
                                                    </Tooltip>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"in-mail"}
                                                draggableId="in-mail"
                                                index={3}
                                                isDragDisabled={isDisabledComponent(
                                                    "in-mail"
                                                )}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <Tooltip
                                                        title={
                                                            isDisabledComponent(
                                                                "in-mail"
                                                            )
                                                                ? "Inmail can only be sent once"
                                                                : null
                                                        }
                                                    >
                                                        <p
                                                            className={`${
                                                                isDisabledComponent(
                                                                    "in-mail"
                                                                )
                                                                    ? style[
                                                                          "wk__itemLink-disabled"
                                                                      ]
                                                                    : style[
                                                                          "wk__itemLink"
                                                                      ]
                                                            }
                                                    `}
                                                            ref={
                                                                dragProvider.innerRef
                                                            }
                                                            {...dragProvider.dragHandleProps}
                                                            {...dragProvider.draggableProps}
                                                        >
                                                            LinkedIn Inmail
                                                        </p>
                                                    </Tooltip>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"delay"}
                                                draggableId="delay"
                                                index={2}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        Time Delay
                                                    </p>
                                                )}
                                            </Draggable>
                                            <Draggable
                                                key={"linked-in-msg"}
                                                draggableId="linked-in-msg"
                                                index={4}
                                            >
                                                {(
                                                    dragProvider: DraggableProvided
                                                ) => (
                                                    <p
                                                        className={
                                                            style[
                                                                "wk__itemLink"
                                                            ]
                                                        }
                                                        ref={
                                                            dragProvider.innerRef
                                                        }
                                                        {...dragProvider.dragHandleProps}
                                                        {...dragProvider.draggableProps}
                                                    >
                                                        LinkedIn Message
                                                    </p>
                                                )}
                                            </Draggable>
                                        </div>
                                        <div className={style["wk__item"]}>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                mb={1}
                                            >
                                                <p
                                                    className={
                                                        style["wk__itemHead"]
                                                    }
                                                >
                                                    Saved Workflows
                                                </p>
                                                <Tooltip
                                                    title="Create new workflow"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setSelectedWorkflow(
                                                                null
                                                            )
                                                        }
                                                        disabled={
                                                            componentList.length ===
                                                            0
                                                        }
                                                    >
                                                        <AddIcon
                                                            fontSize="small"
                                                            color={
                                                                componentList.length ===
                                                                0
                                                                    ? "disabled"
                                                                    : "primary"
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                            {workflowList.map((workflow) => (
                                                <p
                                                    className={`${
                                                        selectedWorkflow &&
                                                        selectedWorkflow._id ===
                                                            workflow._id
                                                            ? style[
                                                                  "wk__templateLink-active"
                                                              ]
                                                            : ""
                                                    } ${
                                                        style[
                                                            "wk__templateLink"
                                                        ]
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedWorkflow(
                                                            workflow
                                                        );
                                                    }}
                                                >
                                                    {workflow.name}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </div> */}
                        {isTemplatesUnAvailable && !isLoading ? (
                            <EmptyTemplatePlaceholder />
                        ) : (
                            <Droppable droppableId="col-2">
                                {(dropProvider: DroppableProvided) => (
                                    <div
                                        className={style["wk__main"]}
                                        style={{
                                            maxHeight: user.emailAuthorized
                                                ? "calc(100vh - 252px)"
                                                : "calc(100vh - 272px)",
                                        }}
                                        ref={dropProvider.innerRef}
                                        {...dropProvider.droppableProps}
                                    >
                                        <div className={style["wk__legendBox"]}>
                                            <div
                                                className={
                                                    style["wk__legendWrapper"]
                                                }
                                            >
                                                <div
                                                    className={
                                                        style["wk__flowWrapper"]
                                                    }
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    {/* <SetupFlow /> */}
                                                    <div
                                                        className={
                                                            style["wk__mainBox"]
                                                        }
                                                    >
                                                        {componentList.map(
                                                            (val, i) => {
                                                                const isFirstComponent =
                                                                    false;
                                                                // checkFirstComponent(
                                                                //     {
                                                                //         componentList,
                                                                //         index: i,
                                                                //         type: val.type,
                                                                //     }
                                                                // ) &&
                                                                // workflowMode ===
                                                                //     "PERSONALIZED_WORKFLOW";
                                                                if (
                                                                    val?.type ===
                                                                    "email"
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            {!isFirstComponent ? (
                                                                                <Email
                                                                                    index={
                                                                                        i
                                                                                    }
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                    isOpen={
                                                                                        val.isOpen
                                                                                    }
                                                                                    subject={
                                                                                        val.subject
                                                                                    }
                                                                                    editorVal={
                                                                                        val.editorVal
                                                                                    }
                                                                                    setSubject={
                                                                                        handleSubjectChange
                                                                                    }
                                                                                    setEditorVal={
                                                                                        handleEditorValueChange
                                                                                    }
                                                                                    toggleCollapse={
                                                                                        handleCollapseChange
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    handleDuplicate={
                                                                                        handleDuplicate
                                                                                    }
                                                                                    type={
                                                                                        val.type
                                                                                    }
                                                                                    cloneDisabled={
                                                                                        isEmailComponentDisabled
                                                                                    }
                                                                                    isLoading={
                                                                                        isLoading
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <FirstOccurence
                                                                                    index={
                                                                                        i
                                                                                    }
                                                                                    type={
                                                                                        val.type
                                                                                    }
                                                                                    isOpen={
                                                                                        val.isOpen
                                                                                    }
                                                                                    handleDuplicate={
                                                                                        handleDuplicate
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    toggleCollapse={
                                                                                        handleCollapseChange
                                                                                    }
                                                                                    cloneDisabled={
                                                                                        isEmailComponentDisabled
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </>
                                                                    );
                                                                } else if (
                                                                    val?.type ===
                                                                    "rem"
                                                                ) {
                                                                    return (
                                                                        <Reminder
                                                                            index={
                                                                                i
                                                                            }
                                                                            key={
                                                                                i
                                                                            }
                                                                            isOpen={
                                                                                val.isOpen
                                                                            }
                                                                            editorVal={
                                                                                val.editorVal
                                                                            }
                                                                            setEditorVal={
                                                                                handleEditorValueChange
                                                                            }
                                                                            toggleCollapse={
                                                                                handleCollapseChange
                                                                            }
                                                                            handleRemove={
                                                                                handleRemove
                                                                            }
                                                                            handleDuplicate={
                                                                                handleDuplicate
                                                                            }
                                                                            type={
                                                                                val.type
                                                                            }
                                                                            isLoading={
                                                                                isLoading
                                                                            }
                                                                        />
                                                                    );
                                                                } else if (
                                                                    val?.type ===
                                                                    "delay"
                                                                ) {
                                                                    return (
                                                                        <Delay
                                                                            index={
                                                                                i
                                                                            }
                                                                            key={
                                                                                i
                                                                            }
                                                                            isOpen={
                                                                                val.isOpen
                                                                            }
                                                                            toggleCollapse={
                                                                                handleCollapseChange
                                                                            }
                                                                            handleRemove={
                                                                                handleRemove
                                                                            }
                                                                            handleDuplicate={
                                                                                handleDuplicate
                                                                            }
                                                                            hours={
                                                                                val.hours
                                                                            }
                                                                            mins={
                                                                                val.mins
                                                                            }
                                                                            days={
                                                                                val.days
                                                                            }
                                                                            weeks={
                                                                                val.weeks
                                                                            }
                                                                            handleTimeChange={
                                                                                handleTimeChange
                                                                            }
                                                                            isLoading={
                                                                                isLoading
                                                                            }
                                                                        />
                                                                    );
                                                                } else if (
                                                                    val?.type ===
                                                                    "linked-in-msg"
                                                                ) {
                                                                    return (
                                                                        <Reminder
                                                                            index={
                                                                                i
                                                                            }
                                                                            key={
                                                                                i
                                                                            }
                                                                            isOpen={
                                                                                val.isOpen
                                                                            }
                                                                            editorVal={
                                                                                val.editorVal
                                                                            }
                                                                            setEditorVal={
                                                                                handleEditorValueChange
                                                                            }
                                                                            toggleCollapse={
                                                                                handleCollapseChange
                                                                            }
                                                                            handleRemove={
                                                                                handleRemove
                                                                            }
                                                                            handleDuplicate={
                                                                                handleDuplicate
                                                                            }
                                                                            type={
                                                                                val.type
                                                                            }
                                                                            isLoading={
                                                                                isLoading
                                                                            }
                                                                        />
                                                                    );
                                                                } else if (
                                                                    val?.type ===
                                                                    "connection-request"
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            {!isFirstComponent ? (
                                                                                <Reminder
                                                                                    index={
                                                                                        i
                                                                                    }
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                    isOpen={
                                                                                        val.isOpen
                                                                                    }
                                                                                    editorVal={
                                                                                        val.editorVal
                                                                                    }
                                                                                    setEditorVal={
                                                                                        handleEditorValueChange
                                                                                    }
                                                                                    toggleCollapse={
                                                                                        handleCollapseChange
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    handleDuplicate={
                                                                                        handleDuplicate
                                                                                    }
                                                                                    type={
                                                                                        val.type
                                                                                    }
                                                                                    isLoading={
                                                                                        isLoading
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <FirstOccurence
                                                                                    index={
                                                                                        i
                                                                                    }
                                                                                    type={
                                                                                        val.type
                                                                                    }
                                                                                    isOpen={
                                                                                        val.isOpen
                                                                                    }
                                                                                    handleDuplicate={
                                                                                        handleDuplicate
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    toggleCollapse={
                                                                                        handleCollapseChange
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </>
                                                                    );
                                                                } else if (
                                                                    val?.type ===
                                                                    "in-mail"
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            {!isFirstComponent ? (
                                                                                <Email
                                                                                    index={
                                                                                        i
                                                                                    }
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                    isOpen={
                                                                                        val.isOpen
                                                                                    }
                                                                                    subject={
                                                                                        val.subject
                                                                                    }
                                                                                    editorVal={
                                                                                        val.editorVal
                                                                                    }
                                                                                    setSubject={
                                                                                        handleSubjectChange
                                                                                    }
                                                                                    setEditorVal={
                                                                                        handleEditorValueChange
                                                                                    }
                                                                                    toggleCollapse={
                                                                                        handleCollapseChange
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    handleDuplicate={
                                                                                        handleDuplicate
                                                                                    }
                                                                                    type={
                                                                                        val.type
                                                                                    }
                                                                                    isLoading={
                                                                                        isLoading
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <FirstOccurence
                                                                                    index={
                                                                                        i
                                                                                    }
                                                                                    type={
                                                                                        val.type
                                                                                    }
                                                                                    isOpen={
                                                                                        val.isOpen
                                                                                    }
                                                                                    handleDuplicate={
                                                                                        handleDuplicate
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    toggleCollapse={
                                                                                        handleCollapseChange
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </>
                                                                    );
                                                                } else
                                                                    return null;
                                                            }
                                                        )}
                                                    </div>
                                                    {/* {showDragMessage ? (
                                                <EmptySetupFlowMessage />
                                            ) : null} */}
                                                </div>
                                                {/* {workflowMode ===
                                        "PERSONALIZED_WORKFLOW" ? (
                                            <HyperPersonalizationPane />
                                        ) : (
                                            <Recommendation
                                                open={Boolean(
                                                    selectedWorkflow?.name
                                                )}
                                            />
                                        )} */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        )}
                    </div>
                </DragDropContext>
                <div style={{ position: "relative", alignSelf: "flex-end" }}>
                    <Button
                        variant="contained"
                        color={
                            location.state?.candidates ? "success" : "primary"
                        }
                        onClick={showSelectWkModal}
                        disabled={isLoading || overlayWorkflow}
                        sx={{
                            padding: "6px 36px",
                            border: "none",
                            width: "fit-content",
                        }}
                    >
                        {selectedCandidates ? "Schedule Outreach" : "Save"}
                    </Button>
                    <img
                        src={trophyGIF}
                        alt="trophy"
                        style={{
                            position: "absolute",
                            bottom: "35px",
                            width: "170px",
                            margin: "0 auto",
                            right: 0,
                        }}
                    />
                </div>
                <Modal
                    open={addConfirmWorkflowModal.open}
                    onOk={closeAddConfirmWorkflowModal}
                    onCancel={closeAddConfirmWorkflowModal}
                    title={""}
                    width={"fit-content"}
                >
                    <svg
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={style["wk__greenTick"]}
                    >
                        <g clipPath="url(#clip0_1728_22026)">
                            <path
                                d="M11.5 0C5.15865 0 0 5.15865 0 11.5C0 17.8414 5.15865 23 11.5 23C17.8414 23 23 17.8414 23 11.5C23 5.15865 17.8414 0 11.5 0Z"
                                fill="#4FCA64"
                            />
                            <path
                                d="M17.3275 9.06192L11.0983 15.291C10.9114 15.4779 10.6661 15.5719 10.4208 15.5719C10.1755 15.5719 9.93018 15.4779 9.74329 15.291L6.62877 12.1764C6.25395 11.8018 6.25395 11.1961 6.62877 10.8214C7.00341 10.4466 7.60898 10.4466 7.9838 10.8214L10.4208 13.2584L15.9725 7.70689C16.3472 7.33208 16.9527 7.33208 17.3275 7.70689C17.7022 8.08154 17.7022 8.6871 17.3275 9.06192Z"
                                fill="#FAFAFA"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1728_22026">
                                <rect width="23" height="23" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p className={style["wk__confirmText"]}>
                        Workflow triggered successfully
                    </p>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        mt={2}
                    >
                        {/* <Button
                            variant="outlined"
                            onClick={closeAddConfirmWorkflowModal}
                        >
                            Cancel
                        </Button> */}
                        <Button
                            variant="contained"
                            onClick={() => {
                                // const url = `/projects/${location.state?.projectId}/statistics?workflow=${addConfirmWorkflowModal.workflowId}`;
                                const url = `/projects/${location.state?.projectId}`;
                                navigate(url);
                            }}
                        >
                            Go to project
                        </Button>
                    </Stack>
                </Modal>
            </div>
            <TourTriggerModal />
            {modalStatus === "SAVE_WORKFLOW_NAME" ? (
                <BaseModal onClose={closeModal}>
                    <>
                        <Stack>
                            <Typography variant="h6">{title}</Typography>
                            {!user.emailAuthorized ? (
                                <Typography fontSize={14} color="red" mb={3}>
                                    It is necessary to authorize your email
                                    provider at this step
                                </Typography>
                            ) : null}
                        </Stack>
                        {user.emailAuthorized ? (
                            <>
                                {/* <CustomMUIInput
                                    placeholder="Enter Workflow Name"
                                    value={workflowData.name}
                                    onChange={(e) =>
                                        dispatch(
                                            setSingleWorkflowDataProperty({
                                                key: "name",
                                                value: e.target.value,
                                            })
                                        )
                                    }
                                /> */}
                                {/* {!companyVerified && (
                                    <>
                                        <CustomMUIInput
                                            placeholder="Enter Company Name"
                                            value={workflowData.comapnyName}
                                            onChange={(e) =>
                                                dispatch(
                                                    setSingleWorkflowDataProperty({
                                                        key: "comapnyName",
                                                        value: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                        <CustomMUIInput
                                            placeholder="Enter Company Link"
                                            value={workflowData.companyLink}
                                            onChange={(e) =>
                                                dispatch(
                                                    setSingleWorkflowDataProperty({
                                                        key: "companyLink",
                                                        value: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                    </>
                                )} */}
                                {errors?.["workflowName"] ? (
                                    <Typography
                                        fontSize={14}
                                        style={{ color: "#ff0000d6" }}
                                    >
                                        {errors?.["workflowName"]}
                                    </Typography>
                                ) : null}
                                {isCandidatesSelected ? (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DateTimePicker
                                            label="Schedule"
                                            value={workflowData.date}
                                            onChange={(date: Date | null) => {
                                                if (date) {
                                                    dispatch(
                                                        setSingleWorkflowDataProperty(
                                                            {
                                                                key: "date",
                                                                value: date,
                                                            }
                                                        )
                                                    );
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{ marginTop: "24px" }}
                                                />
                                            )}
                                            InputProps={{
                                                style: {
                                                    fontFamily: "Poppins",
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                ) : null}
                                {/* <Stack direction="row" alignItems="center" mt={1}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        Save workflow for later
                                    </Typography>
                                    <Switch
                                        checked={saveWorkflowForLater}
                                        onChange={(e) =>
                                            dispatch(
                                                changeSaveWorkflowForLater(
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                </Stack> */}
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    spacing={2}
                                    mt={3}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleWorkflowCreate}
                                        disabled={isCreateLoading}
                                    >
                                        <ButtonTextWithLoading
                                            isLoading={
                                                isCreateLoading ||
                                                isAddToWorkflowLoading
                                            }
                                            text={createBtnText}
                                        />
                                    </Button>
                                </Stack>
                            </>
                        ) : (
                            <MailAuthConnect variant="vertical" />
                        )}
                    </>
                </BaseModal>
            ) : null}
            {modalStatus === "CREATED_WORKFLOW" ? (
                <BaseModal onClose={closeModal}>
                    <Stack spacing={2}>
                        <CheckCircleIcon
                            fontSize="large"
                            style={{
                                alignSelf: "center",
                                color: "#4BB543",
                                fontSize: "60px",
                            }}
                        />
                        <Typography
                            variant="body2"
                            style={{ fontSize: "22px" }}
                            textAlign="center"
                        >
                            Workflow{" "}
                            {selectedWorkflow?.name ? "edited" : "created"}{" "}
                            successfully!
                        </Typography>
                        <Typography
                            style={{ fontSize: "18px" }}
                            textAlign="center"
                        >
                            Do you want to start the outreach?
                        </Typography>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                gap: "1rem",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleNextModal}
                            >
                                Yes
                            </Button>
                            <Button variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                        </div>
                    </Stack>
                </BaseModal>
            ) : null}
            {modalStatus === "OUTREACH_MODAL" ? (
                <BaseModal onClose={closeModal}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                    >
                        <Typography variant="h6">Start Outreach</Typography>
                        <CustomMUIInput
                            disabled={true}
                            placeholder="Enter Workflow Name"
                            value={workflowData.name}
                            onChange={(e) =>
                                dispatch(
                                    setSingleWorkflowDataProperty({
                                        key: "name",
                                        value: e.target.value,
                                    })
                                )
                            }
                        />
                        {!selectedCandidates ? <SelectProject /> : null}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Date&Time picker"
                                value={workflowData.date}
                                onChange={(date: Date | null) => {
                                    if (date) {
                                        dispatch(
                                            setSingleWorkflowDataProperty({
                                                key: "date",
                                                value: date,
                                            })
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                                InputProps={{
                                    style: { fontFamily: "Poppins" },
                                }}
                            />
                        </LocalizationProvider>
                        {userConnectionReqStats || userInmailStats ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                {userConnectionReqStats ? (
                                    <Typography
                                        fontSize={12}
                                        fontStyle="italic"
                                    >
                                        Connection Req. Sent:{" "}
                                        <span className={style["wk__stats"]}>
                                            {userConnectionReqStats}
                                        </span>
                                    </Typography>
                                ) : null}
                                {userInmailStats ? (
                                    <Typography
                                        fontSize={12}
                                        fontStyle="italic"
                                    >
                                        Inmails Sent:{" "}
                                        <span className={style["wk__stats"]}>
                                            {userInmailStats}
                                        </span>
                                    </Typography>
                                ) : null}
                            </Stack>
                        ) : null}
                        <Stack direction="row" spacing={2} alignSelf="flex-end">
                            <Button variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleOutReachStart}
                                disabled={isAddToWorkflowLoading}
                            >
                                <ButtonTextWithLoading
                                    isLoading={isAddToWorkflowLoading}
                                    text="Start"
                                />
                            </Button>
                        </Stack>
                    </div>
                </BaseModal>
            ) : null}
            {/* {modalStatus === "PERSONALIZED_MODAL" ? (
                <PersonalizationModal
                    handleOverlay={() => setOverlayWorkflow(true)}
                />
            ) : null} */}
            {showEditTemplatesModal ? <HyperPersonalizationEditModal /> : null}
            {modalStatus === "PERSONALIZED_INPUTS" ? (
                <PersonalizedInputsModal
                    candidateIDs={selectedCandidates}
                    projectID={selectedProjectFromWorkflows}
                />
            ) : null}
            {creditRewardModal.open ? (
                <CreditRewardModal
                    open={creditRewardModal.open}
                    onClose={closeCreditRewardModal}
                    workflowId={creditRewardModal.workflowId}
                    workflowCredits={creditRewardModal.workflowCredits}
                />
            ) : null}
            {openSampleOutreachModal ? (
                <SampleProjectOutreachModal
                    onClose={() => setOpenSampleOutreachModal(false)}
                />
            ) : null}
        </div>
    );
}
