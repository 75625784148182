import { useEffect, useRef, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { Collapse, IconButton, Skeleton, Tooltip } from "@mui/material";
import { Dropdown, MenuProps } from "antd";
import { get, isEmpty } from "lodash";
import { Draggable, type DraggableProvided } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import {
    chevronDownIconBlue,
    cloneIcon,
    personaliseIcon,
    trashGrayIcon,
} from "../../../../constant";
import {
    createNewTemplate,
    selectTemplateList,
    setEditMsgPopup,
} from "../../../template/index.reducer";
import style from "./index.module.scss";
import "react-quill/dist/quill.snow.css";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import ReactQuill from "react-quill";

import { setErrorNotification } from "../../../../components/Notification/index.reducer";
import TextAreaWithWordCount from "../../../../components/TextAreaWithWordCount/TextAreaWithWordCount";
import { LINKED_CONN_REQ_WORD_LIMIT } from "../../../../utils/Constants";
import { isWordsLimitExceeded } from "../../../../utils/helper";
import { ITemplate } from "../../../template/template.types";

interface reminderPropsType {
    isOpen: boolean;
    index: number;
    editorVal?: string;
    setEditorVal?: (tarInd: number, newValue: string) => void;
    toggleCollapse: (tarInd: number) => void;
    handleRemove?: (tarInd: number) => void;
    handleDuplicate?: (tarInd: number) => void;
    type?: string;
    readable?: boolean;
    isLoading?: boolean;
}

function fieldName(type: string) {
    switch (type) {
        case "linked-in-msg":
            return "LinkedIn Message";
        case "connection-request":
            return "Connection Request";
        default:
            return "Reminder";
    }
}

export default function Reminder(props: reminderPropsType) {
    const {
        index,
        editorVal = "",
        setEditorVal,
        isOpen,
        toggleCollapse,
        handleRemove,
        handleDuplicate,
        type = "rem",
        readable,
        isLoading,
    } = props;

    const dispatch = useDispatch();
    const templateList = useSelector(selectTemplateList);

    const bodyRef = useRef(null);
    const [title, setTitle] = useState<string>("");
    const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [tempDropDown, setTempDropDown] = useState<MenuProps["items"]>([]);
    const [checkInputFocus, setCheckInputFocus] = useState<{
        index: number;
        value: string;
    }>({
        index: 0,
        value: "",
    });
    const [selectedTemplate, setSelectedTemplate] =
        useState<string>("Select Template");

    // const showDeleteModal = () => setIsDeleteModalOpen(true);
    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const showTemplateModal = () => {
        const isMoreWords = isWordsLimitExceeded(
            editorVal,
            LINKED_CONN_REQ_WORD_LIMIT
        );

        if (isMoreWords) {
            dispatch(
                setErrorNotification(
                    `Please keep the word count under ${LINKED_CONN_REQ_WORD_LIMIT}`
                )
            );
            return;
        }

        if (!editorVal?.replace(/<[^>]*>/g, "")) {
            dispatch(setErrorNotification("Body can't be empty"));
            return;
        } else {
            setOpenTemplateModal(true);
        }
    };
    const closeTemplateModal = () => {
        setTitle("");
        setOpenTemplateModal(false);
    };

    useEffect(() => {
        if (isEmpty(templateList)) return;
        let templates: ITemplate[] = [];
        for (let val of templateList) {
            if (type === "linked-in-msg") {
                if (val.type === "LI_MESSAGE_SEND") templates.push(val);
            } else if (type === "connection-request") {
                if (val.type === "CONNECTION_REQUEST") templates.push(val);
            }
        }

        const dropDown: MenuProps["items"] = [];
        for (let i = 0; i < templates.length; i++) {
            dropDown.push({
                label: (
                    <p
                        className={style["sp__link"]}
                        onClick={() => {
                            setSelectedTemplate(templates[i].name);
                            if (setEditorVal) {
                                setEditorVal(index, templates[i].body);
                            }
                        }}
                    >
                        {templates[i].name}
                    </p>
                ),
                key: i.toString(),
            });
        }
        setTempDropDown(dropDown);
    }, [templateList]);

    const handleSave = () => {
        if (!title) {
            dispatch(setErrorNotification("Template name can't be empty"));
            return;
        }

        dispatch(
            createNewTemplate({
                name: title,
                body: editorVal,
                type:
                    type === "linked-in-msg"
                        ? "LI_MESSAGE_SEND"
                        : "CONNECTION_REQUEST",
            })
        );
        closeTemplateModal();
    };

    const handlePersonaliseValues = (key: string) => {
        if (!checkInputFocus.value || !setEditorVal) return;

        if (checkInputFocus.value === "body") {
            // @ts-ignore
            const cursorPosition = bodyRef.current.selectionStart;
            const tempEditorVal =
                editorVal?.substring(0, cursorPosition) +
                key +
                editorVal?.substring(cursorPosition);
            setEditorVal(checkInputFocus.index, tempEditorVal);
            // @ts-ignore
            bodyRef.current?.setSelectionRange(
                cursorPosition + 1,
                cursorPosition + 1
            );
        }
    };

    const handleReactQuillClick = () => {
        dispatch(setEditMsgPopup(true));
        setTimeout(() => {
            dispatch(setEditMsgPopup(false));
        }, 500);
    };

    const personaliseOptions: MenuProps["items"] = [
        {
            label: "{candidateName}",
            key: "{candidateName}",
            onClick: () => handlePersonaliseValues("{candidateName}"),
        },
        {
            label: "{senderName}",
            key: "{senderName}",
            onClick: () => handlePersonaliseValues("{senderName}"),
        },
        {
            label: "{jobTitle}",
            key: "{jobTitle}",
            onClick: () => handlePersonaliseValues("{jobTitle}"),
        },
        {
            label: "{companyName}",
            key: "{companyName}",
            onClick: () => handlePersonaliseValues("{companyName}"),
        },
        {
            label: "{companyLink}",
            key: "{companyLink}",
            onClick: () => handlePersonaliseValues("{companyLink}"),
        },
    ];

    if (readable || !handleDuplicate || !handleRemove || !setEditorVal) {
        return (
            <div className={style["rem__container"]}>
                <div
                    className={`${style["rem__legendBox"]} ${
                        index === 0 ? style["rem__legendBox-start"] : ""
                    }`}
                >
                    {isOpen ? (
                        <div className={style["rem__actionbar"]}>
                            {handleDuplicate ? (
                                <div
                                    className={style["rem__actionBtn"]}
                                    onClick={() => handleDuplicate(index)}
                                >
                                    <Tooltip title={"Duplicate"}>
                                        <img src={cloneIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                            {handleRemove ? (
                                <div
                                    className={style["rem__actionBtn"]}
                                    onClick={() => handleRemove(index)}
                                >
                                    <Tooltip title={"Delete"}>
                                        <img src={trashGrayIcon} alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <p
                        className={style["rem__legend"]}
                        onClick={() => toggleCollapse(index)}
                    >
                        {type && fieldName(type)}{" "}
                        <img
                            className={isOpen ? style["rem__invert"] : ""}
                            src={chevronDownIconBlue}
                            alt=""
                        />
                    </p>
                </div>
                <Collapse in={isOpen}>
                    <div className={style["rem__body"]}>
                        <textarea
                            ref={bodyRef}
                            value={editorVal}
                            disabled={readable}
                            rows={5}
                            style={{
                                width: "100%",
                                resize: "none",
                                padding: 10,
                            }}
                        />
                    </div>
                </Collapse>
            </div>
        );
    }
    return (
        <>
            <Draggable draggableId={`rem${index}`} index={index}>
                {(dragProvider: DraggableProvided) => (
                    <div
                        className={style["rem"]}
                        ref={dragProvider.innerRef}
                        {...dragProvider.dragHandleProps}
                        {...dragProvider.draggableProps}
                    >
                        <div
                            className={style["rem__container"]}
                            id="workflow-reminder"
                        >
                            <div className={style["rem__legendBox"]}>
                                {/* {isOpen ? (
                                    <div className={style["rem__actionbar"]}>
                                        <div
                                            className={style["rem__actionBtn"]}
                                            onClick={() =>
                                                handleDuplicate(index)
                                            }
                                        >
                                            <Tooltip title={"Duplicate"}>
                                                <img src={cloneIcon} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div
                                            className={style["rem__actionBtn"]}
                                            onClick={() => handleRemove(index)}
                                        >
                                            <Tooltip title={"Delete"}>
                                                <img
                                                    src={trashGrayIcon}
                                                    alt=""
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : null} */}
                                <p
                                    className={style["rem__legend"]}
                                    onClick={() => toggleCollapse(index)}
                                >
                                    {type && fieldName(type)}{" "}
                                    <img
                                        className={
                                            isOpen ? style["rem__invert"] : ""
                                        }
                                        src={chevronDownIconBlue}
                                        alt=""
                                    />
                                </p>
                            </div>
                            <Collapse in={isOpen}>
                                <div className={style["rem__body"]}>
                                    {/* {type !== "rem" ? (
                                        <div
                                            className={style["rem__selectBox"]}
                                        >
                                            <Dropdown
                                                trigger={["click"]}
                                                menu={{ items: tempDropDown }}
                                            >
                                                <div
                                                    className={
                                                        style["rem__select"]
                                                    }
                                                >
                                                    <p>{selectedTemplate}</p>
                                                </div>
                                            </Dropdown>
                                            <Tooltip title={"Personalise"}>
                                                <Dropdown
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: personaliseOptions,
                                                    }}
                                                >
                                                    <img
                                                        className={
                                                            style[
                                                                "rem__select-personalise"
                                                            ]
                                                        }
                                                        src={personaliseIcon}
                                                        alt="personalise-icon"
                                                    />
                                                </Dropdown>
                                            </Tooltip>
                                        </div>
                                    ) : null} */}
                                    {isLoading ? (
                                        <Skeleton
                                            variant="rounded"
                                            width="100%"
                                            height={200}
                                        />
                                    ) : (
                                        <>
                                            <div
                                                onClick={handleReactQuillClick}
                                            >
                                                <ReactQuill
                                                    readOnly={true}
                                                    ref={bodyRef}
                                                    value={editorVal}
                                                    onChange={(value) => {
                                                        setEditorVal(
                                                            index,
                                                            value
                                                        );
                                                    }}
                                                    onFocus={() => {
                                                        setCheckInputFocus({
                                                            index,
                                                            value: "body",
                                                        });
                                                    }}
                                                />
                                                {/* <TextAreaWithWordCount
                                                    ref={bodyRef}
                                                    customValue={editorVal}
                                                    disabled={true}
                                                    handleOnChange={(val) =>
                                                        setEditorVal(index, val)
                                                    }
                                                    wordLimit={
                                                        LINKED_CONN_REQ_WORD_LIMIT
                                                    }
                                                    rows={5}
                                                    onFocus={() => {
                                                        setCheckInputFocus({
                                                            index,
                                                            value: "body",
                                                        });
                                                    }}
                                                /> */}
                                            </div>
                                            <div
                                                className={style["rem__btnBox"]}
                                            >
                                                {type !== "rem" ? (
                                                    <>
                                                        <Stack
                                                            direction="row"
                                                            spacing={2}
                                                            alignItems="center"
                                                            my={2}
                                                        >
                                                            <Stack>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                        fontSize:
                                                                            "12px",
                                                                        color: "#6C6C6C",
                                                                    }}
                                                                >
                                                                    *If the
                                                                    candidate is
                                                                    already
                                                                    connected,
                                                                    this step
                                                                    will be
                                                                    skipped
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                        fontSize:
                                                                            "12px",
                                                                        color: "#6C6C6C",
                                                                    }}
                                                                >
                                                                    **To avoid
                                                                    getting your
                                                                    LinkedIn
                                                                    flagged,
                                                                    large batch
                                                                    of
                                                                    connection
                                                                    requests
                                                                    will be
                                                                    broken
                                                                    automatically
                                                                    into daily
                                                                    smaller
                                                                    batches and
                                                                    sent
                                                                </Typography>
                                                            </Stack>
                                                            {/* <Tooltip title="Save as Template">
                                                        <IconButton
                                                            onClick={
                                                                showTemplateModal
                                                            }
                                                        >
                                                            <SaveIcon
                                                                style={{
                                                                    color: "#479bd2",
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                        </Stack>
                                                    </>
                                                ) : null}
                                                {/* <Button label="Add" variant="primary" /> */}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Collapse>
                        </div>
                    </div>
                )}
            </Draggable>
            <Modal
                open={isDeleteModalOpen}
                onOk={closeDeleteModal}
                onCancel={closeDeleteModal}
                title={"Confirm Delete"}
                width={"350px"}
            >
                <div className={style["rem__btnBox"]}>
                    <Button
                        label="Cancel"
                        variant="secondary"
                        onClick={closeDeleteModal}
                    />
                    <Button
                        label="Delete"
                        variant="primary"
                        onClick={() => handleRemove(index)}
                    />
                </div>
            </Modal>
            <Modal
                open={openTemplateModal}
                onOk={closeTemplateModal}
                onCancel={closeTemplateModal}
                title={"Save Template"}
                width={"350px"}
            >
                <input
                    className={style["rem__input"]}
                    type={"text"}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <div className={style["rem__templateBtns"]}>
                    <Button
                        label="Cancel"
                        variant="secondary"
                        onClick={closeTemplateModal}
                    />
                    <Button
                        label="Add"
                        variant="primary"
                        onClick={handleSave}
                    />
                </div>
            </Modal>
        </>
    );
}
