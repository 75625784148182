import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { googleSignInBtn } from "../../constant";
import handleCleverTap from "../../utils/clevertap";
import LogoSvg from "./Images/logo.svg";
import styles from "./Signin.module.scss";
import {
    googleLogin,
    selectIsRegisterApiCalled,
    selectUser,
    signIn,
} from "./Signin.reducer";

const Signin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const userId = get(user, "_id");

    const isRegisterApiCalled = useSelector(selectIsRegisterApiCalled);

    useEffect(() => {
        if (userId) {
            const from = location.state?.from || "/";
            navigate(from, { replace: true });
        }
    }, [userId]);

    const onClickSignin = useCallback(() => {
        clevertapEvent("Login");
        dispatch(googleLogin(""));
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        clevertapEvent("Login");
        dispatch(
            signIn({
                email: email,
                password: password,
                action: signIn.type,
            })
        );
    };

    const clevertapEvent = (eventType: string) => {
        handleCleverTap(eventType);
    };

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <img
                    src={LogoSvg}
                    className={styles.logo}
                    alt="hirequotient-logo"
                />
                <p className={styles.leftHeading}>Start your search...</p>
                <div className={styles["signinRightText-mobile"]}>
                    <h1>
                        Effortlessly
                        <br />
                        Discover Top Talent
                        <br />
                        with EasySource
                    </h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={styles["inputBox"]}>
                        <label>Email</label>
                        <input
                            type="email"
                            placeholder="Please enter your email"
                            value={email}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className={styles["inputBox"]}>
                        <label>Password</label>
                        <input
                            type="password"
                            placeholder="Please enter your password"
                            value={password}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className={styles["btnBox"]}>
                        <button type="submit" disabled={isRegisterApiCalled}>
                            {isRegisterApiCalled ? (
                                <CircularProgress
                                    size={24}
                                    style={{ color: "#fff", margin: "4px 0" }}
                                />
                            ) : (
                                "Login"
                            )}
                        </button>
                        <Link to="/forgot-password">Forgot password?</Link>
                    </div>
                </form>
                <img
                    src={googleSignInBtn}
                    alt="signin-with-google"
                    className={styles["googleSignin"]}
                    onClick={onClickSignin}
                />
                <div className={styles["footer"]}>
                    <p>Don't have an account?</p>
                    <Link to="/signup">Create account</Link>
                </div>
            </div>
            <div className={styles["singinBg"]}>
                <div className={styles["signinContainer"]}>
                    <div className={styles["signinRightText"]}>
                        <h1>
                            <span>
                                Easy<strong>Source</strong>
                            </span>{" "}
                            Recruiter's Free LinkedIn Search
                        </h1>
                        <h2>
                            Find, engage & hire top sales & marketing
                            professionals in the US
                        </h2>
                        {/* <h2 className={styles["efficientHiring"]}>
                            Your Go-to Hiring Solution
                        </h2> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signin;
