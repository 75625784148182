import { createTheme } from "@mui/material/styles";

const getButtonBorderStyles = (
    color?:
        | "inherit"
        | "primary"
        | "secondary"
        | "success"
        | "error"
        | "info"
        | "warning"
) => {
    switch (color) {
        case "success": {
            return "#4fca64";
        }
        case "error": {
            return "#d32f2f";
        }
        default: {
            return "#479bd2";
        }
    }
};

const getBorderFontSizeStyles = (size?: "small" | "medium" | "large") => {
    switch (size) {
        case "small": {
            return 12;
        }
        case "medium": {
            return 14;
        }
        case "large": {
            return 16;
        }
        default: {
            return 14;
        }
    }
};

const theme = createTheme({
    typography: {
        fontFamily: "Poppins",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: "#6b6b6b #2b2b2b",
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "#2b2b2b",
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                        {
                            borderRadius: 8,
                            backgroundColor: "#6b6b6b",
                            minHeight: 24,
                            border: "3px solid #2b2b2b",
                        },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                        {
                            backgroundColor: "#959595",
                        },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                        {
                            backgroundColor: "#959595",
                        },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                        {
                            backgroundColor: "#959595",
                        },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                        {
                            backgroundColor: "#2b2b2b",
                        },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    color:
                        ownerState.variant === "contained"
                            ? "#ffffff"
                            : ownerState.variant === "outlined"
                            ? ownerState.color === "error"
                                ? "#d32f2f"
                                : "#479bd2"
                            : "#479bd2",
                    textTransform: "none",
                    border: "1px solid",
                    backgroundColor:
                        ownerState.variant === "contained"
                            ? ownerState.color === "success"
                                ? "#4fca64"
                                : "#479bd2"
                            : "#ffffff",
                    borderColor: getButtonBorderStyles(ownerState.color),
                    fontSize: getBorderFontSizeStyles(ownerState.size),
                    fontFamily: "Poppins",
                    borderRadius: "2.5rem",
                    "&:hover": {
                        opacity:
                            ownerState.variant === "contained" ? "0.8" : "0.7",
                        borderColor: getButtonBorderStyles(ownerState.color),
                        backgroundColor:
                            ownerState.variant === "contained"
                                ? ownerState.color === "success"
                                    ? "#4fca64"
                                    : "#479bd2"
                                : "#ffffff",
                        boxShadow: "none",
                    },
                    "&:active": {
                        boxShadow: "none",
                        borderColor: "#005cbf",
                    },
                    "&:focus": {
                        boxShadow: `0 0 0 0.2rem ${
                            ownerState.color === "success"
                                ? "rgba(79,202,100,.5)"
                                : "rgba(0,123,255,.5)"
                        }`,
                    },
                    "&.Mui-disabled": {
                        backgroundColor:
                            ownerState.variant === "contained"
                                ? ownerState.color === "success"
                                    ? "#4fca64"
                                    : "#479bd2"
                                : "#ffffff",
                        color:
                            ownerState.variant === "contained"
                                ? "#ffffff"
                                : "#479bd2",
                        opacity: "0.7",
                    },
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: "3px 10px",
                    fontSize: "12px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: "7px 10px",
                },
                head: {
                    background: "#EEEEEE",
                    fontWeight: 700,
                    fontSize: "14px",
                    opacity: 1,
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: 16,
                    fontSize: 16,
                    textTransform: "none",
                    "&.Mui-selected": {
                        color: "#479BD2",
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: "#479BD2",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "30px",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "0 24px 20px",
                },
            },
        },
    },
});

export default theme;
