import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonTextWithLoading from "../../../components/ButtonTextWithLoading";
import {
    setErrorNotification,
    setSuccessNotification,
} from "../../../components/Notification/index.reducer";
import { leverLogo } from "../../../constant";
import useOAuth2 from "../../../hooks/useOAuth2";
import { selectUser, setUser } from "../../Signin/Signin.reducer";
import ConnectWrapper from "./connectWrapper";

const LeverConnect = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const leverRefreshToken = user.leverRefreshToken;

    const { loading, getAuth } = useOAuth2({
        authorizeUrl: `${process.env.REACT_APP_LEVER_AUTH_LINK}/authorize`,
        clientId: `${process.env.REACT_APP_LEVER_CLIENT_ID}`,
        redirectUri: `${process.env.REACT_APP_BASE_URL}/authorize-lever`,
        scope: "offline_access opportunities:write:admin postings:read:admin users:read:admin stages:read:admin uploads:write:admin",
        responseType: "code",
        extraQueryParameters: {
            prompt: "consent",
            audience: process.env.REACT_APP_LEVER_AUDIENCE,
        },
        exchangeCodeForTokenServerURL: `${process.env.REACT_APP_BASE_URL}/api/lever/token`,
        exchangeCodeForTokenMethod: "GET",
        onSuccess: (response) => {
            dispatch(
                setUser({
                    ...user,
                    leverRefreshToken: response.refresh_token,
                })
            );
            dispatch(setSuccessNotification(response.message));
        },
        onError: () =>
            dispatch(setErrorNotification("Error in connecting lever")),
    });

    const handleConnect = () => getAuth();

    return (
        <Button
            variant={"contained"}
            color={leverRefreshToken ? "success" : "primary"}
            onClick={handleConnect}
            disabled={loading || !!leverRefreshToken}
        >
            <ButtonTextWithLoading
                isLoading={loading}
                text={leverRefreshToken ? "Connected" : "Connect"}
            />
        </Button>
    );
};

export default function LeverAts() {
    return (
        <ConnectWrapper
            logo={leverLogo}
            logoAlt="lever-logo"
            title="Lever"
            description="Lever is a top-rated recruiting software that helps your talent teams scale recruitment efforts and hire people faster."
            disabled={true}
        >
            <LeverConnect />
        </ConnectWrapper>
    );
}
