import React, { useEffect, useRef, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Button, IconButton, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Stack from "@mui/system/Stack";
import { Button as AntdButton, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setTourIsOpen } from "../../container/TourContainer/TourContainer.reducer";
import {
    selectFromInvitation,
    setFromInvitation,
} from "../../pages/Signin/Signin.reducer";
import {
    acceptInvite,
    fetchPendingInvitations,
    rejectInvite,
    selectPendingInvitations,
} from "../../pages/manageTeams/manageTeams.reducers";
import {
    selectIsSuperAdminView,
    setIsSuperAdminView,
} from "../../pages/superAdmin/superAdmin.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import CreateProjectModal from "../ProjectModal";
import ReferralButton from "../ReferralButton/ReferralButton";
import CreateWorkflowModal from "../WorkflowModal";
import AccountMenu from "./accountMenu";
import ExtensionBtn from "./extensionBtn";
import style from "./index.module.scss";

const { confirm } = Modal;

type INotificationTile = {
    sender: string;
    org: string;
    inviteId: string;
};

function EmptyNotificationsIcon() {
    return (
        <Stack justifyContent="center" alignItems="center" m={8}>
            <svg
                style={{ alignSelf: "center" }}
                fill="#479BD2"
                width="150px"
                height="150px"
                viewBox="0 0 1000 1000"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#479BD2"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M888 853q-21-48-56-123-7-15 2-28 32-54 46-115t8-123q-8-93-58-172-49-77-127-125-79-49-172-56-88-7-170 24-78 29-137.5 88.5T135 362q-31 81-24 170 7 92 56 172 48 78 125 127 79 50 172 58 62 6 123-8t115-47q13-8 28-2l123 56q11 5 21 .5t14.5-14.5-.5-21zM485 721q-11 27-38.5 38.5t-55 0-39-39 0-55 39-38.5 55 0 38.5 38.5 0 54.5v1zm-25-127q-20-8-28-28t0-40l73-248q8-19 24.5-32.5t38-16 41 5.5 32.5 25 15.5 38-5.5 41L527 566q-8 19-27.5 27.5t-39.5.5z"></path>
                </g>
            </svg>
            <Typography variant="body1" fontWeight={500}>
                No Invitations Yet.
            </Typography>
        </Stack>
    );
}

type ShowConfirmProps = {
    orgName: string;
    handleAccept: () => void;
};

const showConfirm = ({ orgName, handleAccept }: ShowConfirmProps) => {
    confirm({
        title: `Do You Really want to join ${orgName}?`,
        icon: <ExclamationCircleFilled />,
        content: "All Your existing Projects and Data will be lost.",
        centered: true,
        closable: true,
        okText: "Accept",
        style: { padding: "20px 30px" },
        onOk() {
            handleAccept();
            return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.error("Oops errors!"));
        },
        onCancel() {},
    });
};

function NotificationTile({ sender, org, inviteId }: INotificationTile) {
    const dispatch = useDispatch();
    const isAcceptLoading = useSelector(checkIfLoading(acceptInvite.type));
    const isRejectLoading = useSelector(checkIfLoading(rejectInvite.type));
    const isDisable = isAcceptLoading || isRejectLoading;

    const handleAccept = () => {
        dispatch(
            acceptInvite({
                _id: inviteId,
                status: "ACCEPTED",
                action: acceptInvite.type,
            })
        );
    };

    const handleReject = () => {
        dispatch(
            rejectInvite({
                _id: inviteId,
                status: "REJECTED",
                action: rejectInvite.type,
            })
        );
    };

    return (
        <Stack
            spacing={1}
            style={{ border: "1px solid black", width: "100%" }}
            p={2}
            mb={2}
            borderRadius={1}
        >
            <Typography variant="body1">
                {sender}'s Invitation Request
            </Typography>
            <Typography variant="body2">
                Please Review the invitation request for {org}
            </Typography>
            <Stack direction="row" spacing={1} alignSelf="flex-end">
                <AntdButton
                    onClick={handleReject}
                    disabled={isDisable}
                    loading={isRejectLoading}
                >
                    Decline
                </AntdButton>
                <AntdButton
                    loading={isAcceptLoading}
                    disabled={isDisable}
                    type="primary"
                    onClick={() => showConfirm({ orgName: org, handleAccept })}
                >
                    Accept
                </AntdButton>
            </Stack>
        </Stack>
    );
}

function Notifications() {
    const invitations = useSelector(selectPendingInvitations);
    const fromInvitation = useSelector(selectFromInvitation);
    const isPendingInvitationsLoading = useSelector(
        checkIfLoading(fetchPendingInvitations.type)
    );

    const dispatch = useDispatch();
    const notificationIconRef = useRef<HTMLButtonElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (notificationIconRef.current && fromInvitation)
            notificationIconRef.current.click();
    }, [fromInvitation]);

    useEffect(() => {
        if (!anchorEl && fromInvitation) dispatch(setFromInvitation(false));
        dispatch(
            fetchPendingInvitations({ action: fetchPendingInvitations.type })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEl]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const pendingInvitations = invitations.filter(
        ({ status }) => status === "PENDING"
    );

    if (!pendingInvitations.length) {
        return null;
    }

    return (
        <div style={{ position: "relative" }}>
            <IconButton
                size="small"
                ref={notificationIconRef}
                onClick={handleClick}
            >
                <Badge
                    badgeContent={pendingInvitations.length}
                    color="primary"
                    max={100}
                    style={{ fontFamily: "Poppins" }}
                >
                    <NotificationsNoneIcon color="action" />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ marginTop: "10px", zIndex: 2 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack
                    pl={2}
                    py={2}
                    style={{ width: "430px", height: "450px" }}
                >
                    <Typography variant="h6">Notifications</Typography>
                    {isPendingInvitationsLoading ? (
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            m={20}
                        >
                            <Spin />
                        </Stack>
                    ) : pendingInvitations.length ? (
                        <Paper
                            style={{
                                maxHeight: 400,
                                overflow: "auto",
                                paddingRight: "1rem",
                                paddingTop: "0.5rem",
                                maxWidth: 400,
                            }}
                            elevation={0}
                        >
                            {pendingInvitations.map(
                                ({ senderName, org, id }) => {
                                    return (
                                        <NotificationTile
                                            sender={senderName}
                                            org={org}
                                            key={id}
                                            inviteId={id}
                                        />
                                    );
                                }
                            )}
                        </Paper>
                    ) : (
                        <EmptyNotificationsIcon />
                    )}
                </Stack>
            </Popover>
        </div>
    );
}

type NavbarProps = {
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    variant?: "base" | "modified";
    containerStyle?: React.CSSProperties;
};

export default function Navbar({
    value,
    onChange,
    variant = "base",
    containerStyle,
}: NavbarProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const [showReferralModal, setShowReferralModal] = useState<boolean>(false);
    const [isProjectModalOpen, setIsProjectModalOpen] =
        useState<boolean>(false);
    const [isWorkflowModalOpen, setIsWorkflowModalOpen] =
        useState<boolean>(false);

    const closeProjectModal = () => setIsProjectModalOpen(false);

    const closeWorkflowModal = () => setIsWorkflowModalOpen(false);

    const handleStartWalkthrough = () => {
        navigate(`/projects/9999`, {
            replace: true,
        });
        dispatch(setTourIsOpen(true));
    };

    return (
        <div className={style["nav__container"]} style={containerStyle}>
            <div className={style["nav__body"]}>
                {isSuperAdminView && (
                    <Button
                        onClick={() => dispatch(setIsSuperAdminView(false))}
                    >
                        Disable super admin view
                    </Button>
                )}
                {/* {variant === "base" && (
                        <input
                            type="text"
                            placeholder="Search.."
                            className={style["nav__searchBar"]}
                            value={value}
                            onChange={onChange}
                        />
                    )} */}
                <ExtensionBtn />
                <Notifications />
                <Stack direction="row" alignItems="center">
                    <AccountMenu
                        setShowReferralModal={setShowReferralModal}
                        startWalkthrough={handleStartWalkthrough}
                    />
                    <ReferralButton
                        showReferralModal={showReferralModal}
                        setShowReferralModal={setShowReferralModal}
                    />
                </Stack>
            </div>
            <CreateProjectModal
                open={isProjectModalOpen}
                close={closeProjectModal}
            />
            <CreateWorkflowModal
                open={isWorkflowModalOpen}
                close={closeWorkflowModal}
            />
        </div>
    );
}
