import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

import { selectUser } from "../Signin/Signin.reducer";
import GreenhouseAts from "./components/greenhouse";
import JobviteAts from "./components/jobvite";
import LeverAts from "./components/lever";
import LoxoAts from "./components/loxo";
import SlackIntegration from "./components/slack";
import ZohoRecruitAts from "./components/zohoRecruit";

export default function Integrations() {
    const user = useSelector(selectUser);

    return (
        <Stack
            spacing={2}
            py={1}
            height={
                user.emailAuthorized
                    ? "calc(100vh - 20px)"
                    : "calc(100vh - 56px)"
            }
            sx={{ overflowY: "auto" }}
        >
            <SlackIntegration />
            <GreenhouseAts />
            <LoxoAts />
            <LeverAts />
            <ZohoRecruitAts />
            <JobviteAts />
        </Stack>
    );
}
