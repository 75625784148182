import { get, isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { setSuccessNotification } from "../../components/Notification/index.reducer";
import { groupUserEmail, sampleProject } from "../../constant";
import {
    IActionPayload,
    startAction,
    stopAction,
} from "../../store/reducers/loaders.reducer";
import API from "../../utils/API";
import handleCleverTap from "../../utils/clevertap";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { IMessage } from "../message/message.types";
import {
    getProjectCandidates,
    getProjectStats,
    setSelectAllCandidates,
} from "../project/index.reducer";
import { IProject } from "../project/project.types";
import {
    ICreateNotePayload,
    IDeleteProjectPayload,
    IEditNotePayload,
    IGetProjectPayload,
    IGetUserEmailPayload,
    IRemoveCandidateFromProjectPayload,
    ISendEmailPayload,
    IUpdateProjectCreditsPayload,
} from "./allProjects.types";
import {
    createNewNote,
    createNewProject,
    deleteNote,
    deleteProject,
    editNote,
    editProject,
    getActivities,
    getAllProjectsList,
    getCandidate,
    getCandidateEmail,
    getNotes,
    getProject,
    getUserEmail,
    leaveAllProjects,
    postEmailReadReceipt,
    removeCandidateFromProject,
    sendEmail,
    setActivities,
    setAllProjectsList,
    setCandidateEmail,
    setNotes,
    setProject,
    setUserEmail,
    updateProjectCredits,
} from "./index.reducer";

function* getAllProjectsSaga({
    payload,
}: {
    payload: IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/v2/project/list");
        const projectList = get(response, "data") || [];

        const statsResponse = yield call(new API().get, "/v2/project/stats");
        const statsList = get(statsResponse, "data") || [];

        if (!isEmpty(projectList) && !isEmpty(statsList)) {
            projectList.map((item: any) => {
                const projectStats = statsList.find(
                    (stats: any) => item._id === stats.projectId
                );
                const pipelined = get(projectStats, "totalCandidate", 0);
                const responded = get(projectStats, "responded", 0);
                const contacted = get(projectStats, "contacted", 0) + responded;

                item.pipelined = pipelined;
                item.contacted = contacted;
                item.responded = responded;
            });
        }
        yield put(setAllProjectsList([...projectList, sampleProject]));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* createNewProjectSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(
            new API().post,
            "/v2/project/create",
            payload
        );

        yield put(getAllProjectsList({ action: getAllProjectsList.type }));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* editProjectSaga({ payload }: { payload: any }): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().put,
            "/v2/project/update",
            payload
        );
        if (isEmpty(response)) return;

        const state = yield select();
        const allProjectsListStateValue = get(state, "allProjects.projectList");
        const projectStateValue = get(state, "allProjects.project");
        const tempProjectList = allProjectsListStateValue.map(
            (item: IProject) => {
                if (item._id === payload._id) {
                    return {
                        ...item,
                        name: payload.name,
                        dashboardStatus: payload.dashboardStatus,
                    };
                }
                return item;
            }
        );

        if (payload.onSuccess) payload.onSuccess();
        yield put(
            setProject({
                ...projectStateValue,
                name: payload.name,
            })
        );
        yield put(setAllProjectsList(tempProjectList));
        yield put(setSuccessNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getProjectSaga({
    payload,
}: {
    payload: IGetProjectPayload & IActionPayload;
}): SagaIterator {
    try {
        const { projectId, action } = payload;

        yield put(startAction({ action }));

        const response = yield call(
            new API().get,
            "/v2/project/get/" + projectId
        );
        if (isEmpty(response?.data)) return;

        yield put(setProject(response.data));
        yield put(getProjectStats({ projectId, action: getProjectStats.type }));
        yield put(
            getProjectCandidates({
                projectId,
                start: 0,
                limit: 50,
                action: getProjectCandidates.type,
            })
        );

        handleCleverTap("Project Details", {
            "Project id": payload?.projectId,
            candidates: response.data.candidates.length || 0,
        });
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteProjectSaga({
    payload,
}: {
    payload: IDeleteProjectPayload;
}): SagaIterator {
    try {
        yield call(new API().put, "/v2/project/delete", {
            projectIds: payload.projectIds,
        });

        yield put(setProject({}));
        yield put(getAllProjectsList({ action: getAllProjectsList.type }));
        yield put(setSuccessNotification("Project deleted successfully"));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* getNotesSaga({
    payload,
}: {
    payload: { id: string } & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(new API().get, "/notes/get/" + payload.id);
        if (isEmpty(response?.data)) return;

        yield put(setNotes(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* createNewNoteSaga({
    payload,
}: {
    payload: ICreateNotePayload & IActionPayload;
}): SagaIterator {
    try {
        const { action, ...rest } = payload;
        yield put(startAction({ action }));
        const response = yield call(new API().post, "/notes/create/", rest);
        yield put(setSuccessNotification(response.message));
        yield put(getNotes({ id: payload.candidateId, action: getNotes.type }));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* editNoteSaga({
    payload,
}: {
    payload: IEditNotePayload & IActionPayload;
}): SagaIterator {
    try {
        const { action, onSuccess, ...rest } = payload;

        yield put(startAction({ action }));
        const response = yield call(new API().put, "/notes/edit", rest);

        if (onSuccess) onSuccess();
        yield put(setSuccessNotification(response.message));
        yield put(getNotes({ id: payload.candidateId, action: getNotes.type }));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* deleteNoteSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(
            //@ts-ignore
            new API().delete,
            "/notes/edit",
            payload
        );

        yield put(getNotes(payload.candidateId));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* getActivitiesSaga({
    payload,
}: {
    payload: { id: string } & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            "/activity/get/" + payload.id
        );
        if (isEmpty(response?.data)) return;

        yield put(setActivities(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCandidateEmailSaga(): SagaIterator {
    try {
        const response = yield call(
            new API().get,
            "/communication/get-candidate-emails"
        );
        if (isEmpty(response?.data)) return;

        yield put(setCandidateEmail(response.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* getUserEmailSaga({
    payload,
}: {
    payload: IGetUserEmailPayload & IActionPayload;
}): SagaIterator {
    try {
        const { action, navigate, tab } = payload;

        yield put(startAction({ action }));

        const response = yield call(
            new API().get,
            "/communication/get-user-emails"
        );
        if (isEmpty(response?.data)) return;

        if (navigate && tab) {
            const emailData = groupUserEmail(response?.data);
            navigate(`/messages/${tab}/${emailData[0].msgs[0].candidate._id}`);
        }
        yield put(setUserEmail(response?.data));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* sendEmailSaga({
    payload,
}: {
    payload: ISendEmailPayload & IActionPayload;
}): SagaIterator {
    try {
        const { action, onSuccess, ...rest } = payload;

        yield put(startAction({ action }));

        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload = {
            ...rest,
        };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidates = [];
        }

        const response = yield call(
            new API().post,
            "/communication/send-email",
            triggerPayload
        );
        if (!response) return;

        if (onSuccess) onSuccess();
        yield put(setSelectAllCandidates(false));
        yield put(getUserEmail({ action: getUserEmail.type }));
        yield put(setSuccessNotification(response.message));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* getCandidateSaga({ payload }: { payload: any }): SagaIterator {
    try {
        const response = yield call(new API().get, "/candidate/get/" + payload);
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* removeCandidateFromProjectSaga({
    payload,
}: {
    payload: IRemoveCandidateFromProjectPayload;
}): SagaIterator {
    try {
        const state = yield select();
        const selectAllCandidatesStateValue = get(
            state,
            "project.selectAllCandidates"
        );

        const triggerPayload = { ...payload };
        if (selectAllCandidatesStateValue) {
            triggerPayload.selectAll = true;
            triggerPayload.candidateIds = [];
        }

        const response = yield call(
            new API().post,
            "/v2/project/candidates-from-project",
            triggerPayload
        );

        if (!response) return;

        yield put(
            getProject({
                projectId: payload.projectId,
                action: getProject.type,
            })
        );
        yield put(setSelectAllCandidates(false));
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    }
}

function* postEmailReadReceiptSaga({
    payload,
}: {
    payload: IActionPayload & { threadId: string };
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().get,
            `/communication/read-receipt/${payload.threadId}`
        );
        if (!response) return;

        const state = yield select();
        const userEmailsStateValue = get(state, "allProjects.userEmail");
        const tmpUserEmails = userEmailsStateValue.map((msg: IMessage) => {
            if (msg.threadId === payload.threadId) {
                return {
                    ...msg,
                    read: true,
                };
            }
            return msg;
        });
        yield put(setUserEmail(tmpUserEmails));
    } catch (err) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

function* updateProjectCreditsSaga({
    payload,
}: {
    payload: IUpdateProjectCreditsPayload & IActionPayload;
}): SagaIterator {
    try {
        yield put(startAction({ action: payload.action }));

        const response = yield call(
            new API().put,
            "/v2/project/update",
            payload.project
        );
        if (isEmpty(response?.data)) return;

        if (payload.onSuccess) payload.onSuccess();
    } catch (err: unknown) {
        console.error(err);
        handleException(err);
    } finally {
        yield put(stopAction({ action: payload.action }));
    }
}

export default function* rootSagas() {
    const tasks = [
        // @ts-ignore
        yield takeLatest(getAllProjectsList.type, getAllProjectsSaga),
        // @ts-ignore
        yield takeLatest(createNewProject.type, createNewProjectSaga),
        // @ts-ignore
        yield takeLatest(editProject.type, editProjectSaga),
        // @ts-ignore
        yield takeLatest(getProject.type, getProjectSaga),
        // @ts-ignore
        yield takeLatest(deleteProject.type, deleteProjectSaga),
        // @ts-ignore
        yield takeLatest(getNotes.type, getNotesSaga),
        // @ts-ignore
        yield takeLatest(createNewNote.type, createNewNoteSaga),
        // @ts-ignore
        yield takeLatest(deleteNote.type, deleteNoteSaga),
        // @ts-ignore
        yield takeLatest(editNote.type, editNoteSaga),
        // @ts-ignore
        yield takeLatest(getActivities.type, getActivitiesSaga),
        // @ts-ignore
        yield takeLatest(getCandidateEmail.type, getCandidateEmailSaga),
        // @ts-ignore
        yield takeLatest(getUserEmail.type, getUserEmailSaga),
        // @ts-ignore
        yield takeLatest(sendEmail.type, sendEmailSaga),
        // @ts-ignore
        yield takeLatest(getCandidate.type, getCandidateSaga),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            removeCandidateFromProject.type,
            removeCandidateFromProjectSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            postEmailReadReceipt.type,
            postEmailReadReceiptSaga
        ),
        // @ts-ignore
        yield takeLatest(
            // @ts-ignore
            updateProjectCredits.type,
            updateProjectCreditsSaga
        ),
    ];
    // @ts-ignore
    yield takeLatest(leaveAllProjects.type, CancelSagas, tasks);
}
