import { IComponentList } from "../../workflow/workflow.types";

type IProps = {
    componentList: IComponentList[];
    type: string;
};

export default function countOccurrences({
    componentList,
    type: currentComponentType,
}: IProps) {
    return componentList.reduce((acc: number, { type }) => {
        if (type === currentComponentType) {
            acc += 1;
        }

        return acc;
    }, 0);
}
