import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import { IActionPayload } from "../../../store/reducers/loaders.reducer";
import { IGetUserListPayload, IUser } from "./userList.types";

const initialState = Object.freeze({
    users: [],
    total: 0,
    totalProjectsCreated: 0,
    totalCandidatesScraped: 0,
    totalEmailsExtracted: 0,
    totalWorkflowsTriggered: 0,
    sortBy: "createdAt",
});

const reducers = {
    getUserList(
        state: any,
        { payload }: { payload: IGetUserListPayload & IActionPayload }
    ) {
        if(payload.sortBy)
            state.sortBy = payload.sortBy;
    },
    setUserList(state: any, { payload }: { payload: IUser[] }) {
        state.users = payload;
    },
    setTotalUserCount(state: any, { payload }: { payload: number }) {
        state.total = payload;
    },
    setTotalProjectsCreated(state: any, { payload }: { payload: number }) {
        state.totalProjectsCreated = payload;
    },
    setTotalCandidatesScraped(state: any, { payload }: { payload: number }) {
        state.totalCandidatesScraped = payload;
    },
    setTotalEmailsExtracted(state: any, { payload }: { payload: number }) {
        state.totalEmailsExtracted = payload;
    },
    setTotalWorkflowsTriggered(state: any, { payload }: { payload: number }) {
        state.totalWorkflowsTriggered = payload;
    },
    setSortBy(state: any, { payload }: { payload: IUser[] }) {
        state.users = payload;
    },
    leavePage(state: any) {},
};

export const page = createSlice({
    name: "userList",
    initialState,
    // @ts-ignore
    reducers,
});

export default page.reducer;
export const {
    getUserList,
    setUserList,
    setTotalUserCount,
    setTotalProjectsCreated,
    setTotalWorkflowsTriggered,
    setTotalCandidatesScraped,
    setTotalEmailsExtracted,
    leavePage,
} = page.actions;

export const selectUsers = (state: RootState) => state.userList.users;
export const selectTotalUsers = (state: RootState) => state.userList.total;
export const selectTotalProjectsCreated = (state: RootState) =>
    state.userList.totalProjectsCreated;
export const selectTotalWorkflowsTriggered = (state: RootState) =>
    state.userList.totalWorkflowsTriggered;
export const selectTotalCandidatesScraped = (state: RootState) =>
    state.userList.totalCandidatesScraped;
export const selectTotalEmailsExtracted = (state: RootState) =>
    state.userList.totalEmailsExtracted;
export const selectSortBy = (state: RootState) =>
    state.userList.sortBy;