import { useRef } from "react";
import Portal from "@mui/base/Portal";
import { useSelector } from "react-redux";

import { selectAllProjects } from "../../pages/allProjects/index.reducer";
import { EXTENSION_LINK } from "../../utils/Constants";
import Nudge from "../Nudges/Nudges";
import { selectInstallExtensionNudge } from "../Nudges/Nudges.reducer";
import styles from "./index.module.scss";

type ChromeExtensionProps = {
    variant?: "default" | "low";
};

export default function ChromeExtension({
    variant = "default",
}: ChromeExtensionProps) {
    const container = useRef<HTMLAnchorElement | null>(null);
    const projects = useSelector(selectAllProjects);
    const installExtensionNudge = useSelector(selectInstallExtensionNudge);

    return (
        <>
            <a
                ref={container}
                className={
                    variant === "low"
                        ? styles["blue__btn"]
                        : styles["chrome__btn"]
                }
                href={EXTENSION_LINK}
                target="_blank"
                rel="noreferrer"
            >
                Install Chrome Extension
            </a>
            {variant === "default" &&
                projects.length === 1 &&
                !installExtensionNudge && (
                    <Portal container={container.current}>
                        <Nudge
                            variant="top"
                            type="installExtension"
                            text={"Wait no more, search for candidates now 🚀"}
                            wrapperStyle={{
                                position: "absolute",
                                top: "120%",
                                left: "40%",
                                transform: "translateX(-50%)",
                                minWidth: 250,
                                textAlign: "right",
                                cursor: "default",
                            }}
                            arrowStyle={{
                                margin: "0.75rem 2rem 0",
                            }}
                        />
                    </Portal>
                )}
        </>
    );
}
