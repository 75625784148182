import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";

type Message = {
    id: string;
    question: string;
    answer: string;
    points?: Array<string>;
};

export function InfoParagraph({
    question,
    answer,
    children,
}: {
    question: string;
    answer: string;
    children?: React.ReactNode;
}) {
    return (
        <span>
            <Typography variant="body2" fontWeight="bold">
                {question}
            </Typography>
            <Typography variant="body2">{answer}</Typography>
            {children}
        </span>
    );
}

const messages: Array<Message> = [
    {
        id: nanoid(),
        question: "What is a workflow?",
        answer: "To keep your candidates engaged across channels, you can create automated workflows with emails, Inmails and LinkedIn requests.",
    },
    {
        id: nanoid(),
        question: "When will the workflow stop?",
        answer: "The workflow for a particular candidate will automatically stop if any of these 3 events happen:",
        points: [
            "Email reply is detected",
            "Connection request is accepted",
            "Inmail reply is detected",
        ],
    },
];

export function WorkflowInfo() {
    return (
        <Stack spacing={1}>
            {messages.map((msg) => {
                return msg?.points && msg.points.length ? (
                    <InfoParagraph
                        key={msg.id}
                        question={msg.question}
                        answer={msg.answer}
                    >
                        <ul style={{ padding: "0 1rem" }}>
                            {msg.points.map((point) => {
                                return (
                                    <li key={point}>
                                        <Typography variant="body2">
                                            {point}
                                        </Typography>
                                    </li>
                                );
                            })}
                        </ul>
                    </InfoParagraph>
                ) : (
                    <InfoParagraph
                        key={msg.id}
                        question={msg.question}
                        answer={msg.answer}
                    />
                );
            })}
        </Stack>
    );
}

export default function InfoButton() {
    return (
        <Tooltip title={<WorkflowInfo />}>
            <IconButton>
                <InfoIcon
                    fontSize="medium"
                    style={{ color: "rgba(0, 0, 0, 0.3)" }}
                />
            </IconButton>
        </Tooltip>
    );
}
