import { useEffect, useState } from "react";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom";

import {
    hqInitialsLogo,
    hqLogo,
    layerIcon,
    manageTeamIcon,
    routesMatchArray,
} from "../../constant";
import { setTourIsOpen } from "../../container/TourContainer/TourContainer.reducer";
import { selectUser } from "../../pages/Signin/Signin.reducer";
import {
    selectIsSuperAdminView,
    selectUserDetails,
} from "../../pages/superAdmin/superAdmin.reducer";
import handleCleverTap from "../../utils/clevertap";
import { nameInitials } from "../../utils/helper";
// import CreditsPanel from "../CreditsPanel/creditsPanel";
import { setCanHideWalkThrough } from "../InterActiveWalkthroughModal/interActiveWalkthorughModal.reducer";
import NAEmailAlert from "../NAEmailAlert/NAEmailAlert";
import SourceCandidatesMenu from "../SourceCandidatesMenu/SourceCandidatesMenu";
import style from "./index.module.scss";

let pathKey: number = 1;

function renderLogoBox(minimize: boolean) {
    return (
        <div
            className={style["sidebar__logobox"]}
            style={{
                padding: minimize ? "10px" : "10px 0",
                justifyContent: minimize ? "center" : "initial",
            }}
        >
            {minimize ? (
                <Link to="/">
                    <img
                        src={hqInitialsLogo}
                        alt="hq-logo"
                        className={style["sidebar__logo-small"]}
                    />
                </Link>
            ) : (
                <img
                    className={style["sidebar__logo"]}
                    src="https://storage.googleapis.com/allhqclientpics/61730080f94921c9089ea99f_logo_1669960789205.png"
                    alt="hq-logo"
                />
            )}
        </div>
    );
}

type SidebarItemProps = {
    active: boolean;
    minimize: boolean;
    handleNavigate: ({ path, event }: { path: string; event: string }) => void;
    linkTo: string;
    title: string;
    cleverTapEvent: string;
    icon: React.ReactNode;
};

const SidebarItem = ({
    active,
    minimize,
    handleNavigate,
    linkTo,
    title,
    cleverTapEvent,
    icon,
}: SidebarItemProps) => {
    const postClevertapEvent = (eventType: string) => {
        handleCleverTap(eventType);
    };

    return (
        <div
            className={`${style["sidebar__itemBox"]} ${
                style["sidebar__item"]
            } ${active ? style["sidebar__item--active"] : ""}`}
        >
            {minimize ? (
                <Tooltip title={title} placement="right">
                    <IconButton
                        onClick={() =>
                            handleNavigate({
                                path: linkTo,
                                event: cleverTapEvent,
                            })
                        }
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            ) : (
                <>
                    <Stack sx={{ marginRight: 2.5 }}>{icon}</Stack>
                    <Link
                        to={linkTo}
                        className={style["sidebar__item-single"]}
                        onClick={() => postClevertapEvent(cleverTapEvent)}
                    >
                        {title}
                    </Link>
                </>
            )}
        </div>
    );
};

export default function Sidebar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(selectUser);
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const userDetails = useSelector(selectUserDetails);
    const [minimize, setMinimize] = useState(false);

    const pathArr = matchRoutes(routesMatchArray, location);
    if (pathArr && pathArr.length) pathKey = pathArr[0].route.key;

    useEffect(() => {
        if (location.pathname === "/workflows") {
            setMinimize(true);
        } else {
            setMinimize(false);
        }
    }, [location]);

    const handleStartWalkthrough = () => {
        navigate(`/projects/9999`);
        dispatch(setTourIsOpen(true));
    };

    const clevertapEvent = (eventType: string) => {
        handleCleverTap(eventType);
    };

    const handleNavigate = ({
        path,
        event,
    }: {
        path: string;
        event: string;
    }) => {
        navigate(path);
        clevertapEvent(event);
    };

    const openInteractiveWalkthrough = () => {
        clevertapEvent("Quick Interactive Walkthrough");
        dispatch(setCanHideWalkThrough(false));
    };

    const newUser = isSuperAdminView ? userDetails : user;

    return (
        <div
            className={style["sidebar"]}
            style={{
                width: minimize ? "fit-content" : 260,
            }}
        >
            <div
                className={style["sidebar__container"]}
                style={{
                    padding: minimize ? 8 : 20,
                }}
            >
                {renderLogoBox(minimize)}
                <div
                    className={style["sidebar__userbox"]}
                    style={{
                        justifyContent: minimize ? "center" : "initial",
                    }}
                >
                    {newUser.photoURL ? (
                        <img
                            src={user.photoURL}
                            alt="user profile icon"
                            className={style["sidebar__userimg"]}
                        />
                    ) : (
                        <p className={style["sidebar__avatar"]}>
                            {nameInitials(
                                newUser?.displayName
                                    ? newUser.displayName
                                    : "JD"
                            )}
                        </p>
                    )}
                    {!minimize ? (
                        <p className={style["sidebar__userdetails"]}>
                            {newUser?.displayName}
                        </p>
                    ) : null}
                </div>
                <div className={style["sidebar__linkBox"]}>
                    <SidebarItem
                        active={pathKey === 2}
                        minimize={minimize}
                        title="All projects"
                        cleverTapEvent="Sidebar All projects"
                        icon={
                            <DnsOutlinedIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                    color: "#334d6e",
                                }}
                            />
                        }
                        linkTo="/"
                        handleNavigate={handleNavigate}
                    />
                    <SidebarItem
                        active={pathKey === 7 || pathKey === 10}
                        minimize={minimize}
                        title="Messages & Email"
                        cleverTapEvent="Sidebar Messages & Email"
                        icon={
                            <ForumOutlinedIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                    color: "#334d6e",
                                }}
                            />
                        }
                        linkTo="/messages/sent"
                        handleNavigate={handleNavigate}
                    />
                    {/* <SidebarItem
                        active={pathKey === 4}
                        minimize={minimize}
                        title="Workflows"
                        cleverTapEvent="Sidebar Workflows"
                        icon={<img src={layerIcon} alt="workflows" />}
                        linkTo="/workflows"
                        handleNavigate={handleNavigate}
                    />
                    <SidebarItem
                        active={pathKey === 9}
                        minimize={minimize}
                        title="Manage Team"
                        cleverTapEvent="Sidebar Manage Team"
                        icon={<img src={manageTeamIcon} alt="manage-team" />}
                        linkTo="/teams"
                        handleNavigate={handleNavigate}
                    /> */}
                    <SidebarItem
                        active={pathKey === 11}
                        minimize={minimize}
                        title="Integrations"
                        cleverTapEvent="Sidebar Integrations"
                        icon={
                            <LaunchIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                    color: "#334d6e",
                                }}
                            />
                        }
                        linkTo="/integrations"
                        handleNavigate={handleNavigate}
                    />
                    {user?.isSuperAdmin && !isSuperAdminView && (
                        <>
                            <SidebarItem
                                active={pathKey === 12}
                                minimize={minimize}
                                title="Org List"
                                cleverTapEvent="Sidebar Org List"
                                icon={
                                    <DnsOutlinedIcon
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            color: "#334d6e",
                                        }}
                                    />
                                }
                                linkTo="/superAdmin/orgList"
                                handleNavigate={handleNavigate}
                            />
                            <SidebarItem
                                active={pathKey === 13}
                                minimize={minimize}
                                title="User List"
                                cleverTapEvent="Sidebar User List"
                                icon={
                                    <DnsOutlinedIcon
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            color: "#334d6e",
                                        }}
                                    />
                                }
                                linkTo="/superAdmin/userList"
                                handleNavigate={handleNavigate}
                            />
                            <SidebarItem
                                active={pathKey === 14}
                                minimize={minimize}
                                title="Daily Metrics"
                                cleverTapEvent="Sidebar Daily Metrics"
                                icon={
                                    <DnsOutlinedIcon
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            color: "#334d6e",
                                        }}
                                    />
                                }
                                linkTo="/superAdmin/daily-metrics"
                                handleNavigate={handleNavigate}
                            />
                            <SidebarItem
                                active={pathKey === 15}
                                minimize={minimize}
                                title="Funnel Movement"
                                cleverTapEvent="Sidebar Funnel Movement"
                                icon={
                                    <DnsOutlinedIcon
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            color: "#334d6e",
                                        }}
                                    />
                                }
                                linkTo="/superAdmin/funnel-movement"
                                handleNavigate={handleNavigate}
                            />
                            <SidebarItem
                                active={pathKey === 16}
                                minimize={minimize}
                                title="HC User List"
                                cleverTapEvent="Sidebar HC User List"
                                icon={
                                    <DnsOutlinedIcon
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            color: "#334d6e",
                                        }}
                                    />
                                }
                                linkTo="/humanCircles/userList"
                                handleNavigate={handleNavigate}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className={style["sidebar__footer"]}>
                <NAEmailAlert />
                {/* <CreditsPanel /> */}
                <div className={style["sidebar__helpBox"]}>
                    {minimize ? (
                        <Tooltip
                            title={"How to use the Dashboard?"}
                            placement="right"
                        >
                            <IconButton
                                sx={{
                                    padding: "6px",
                                    margin: "0 14px",
                                }}
                                onClick={openInteractiveWalkthrough}
                            >
                                <PlayCircleIcon
                                    sx={{
                                        width: 28,
                                        height: 28,
                                        color: "rgb(51, 77, 110)",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Button
                            sx={{
                                padding: "4px 8px",
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#334D6E",
                                margin: "0 20px 8px",
                                borderColor: "#d9d9d9",
                                boxShadow:
                                    "rgba(16, 156, 241, 0.24) 0px 4px 10px 0px",
                            }}
                            onClick={openInteractiveWalkthrough}
                        >
                            How to use the Dashboard?
                        </Button>
                    )}
                    {/* <SourceCandidatesMenu
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        btnText="How to use the Dashboard?"
                    >
                        <MenuItem
                            style={{ fontSize: 14, color: "#334D6E" }}
                            onClick={() => {
                                clevertapEvent("Quick Interactive Walkthrough");
                                dispatch(setCanHideWalkThrough(false));
                            }}
                        >
                            Quick Interactive Walkthrough
                        </MenuItem>
                        <MenuItem
                            style={{ fontSize: 14, color: "#334D6E" }}
                            onClick={() => {
                                clevertapEvent("EasySource in 7-Steps");
                                handleStartWalkthrough();
                            }}
                        >
                            EasySource in 7-Steps
                        </MenuItem>
                    </SourceCandidatesMenu> */}
                </div>
                {/* <div className={style["sidebar__copyBox"]}>
                    All rights reserved &copy; &nbsp;{" "}
                    <img
                        className={style["sidebar__copyImg"]}
                        src={hqLogo}
                        alt="hq-logo"
                    />
                </div> */}
            </div>
        </div>
    );
}
