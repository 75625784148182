import { useRef, useState } from "react";
import { Pagination, Spin } from "antd";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { selectUser } from "../../../Signin/Signin.reducer";
import {
    getProjectCandidates,
    getShowByMaxExperience,
    getShowByMinExperience,
    getShowByPersonalEmails,
    getShowByProjectFilters,
    getShowByRelevance,
    selectCandidateCurrentPage,
    selectProjectCandidates,
    setCandidatePage,
} from "../../index.reducer";
import { ICandidate, IProjectView } from "../../project.types";
import GridView from "../gridView";
import ProjectCard from "../projectCard";
import style from "./candidates.module.scss";

interface IProps {
    activeView: IProjectView;
    searchText?: string;
    selectAllCheck?: boolean;
    isSample?: boolean;
}

export default function ProjectCandidates({
    activeView,
    searchText,
    selectAllCheck,
    isSample,
}: IProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const params = useParams();
    const user = useSelector(selectUser);
    const emailAuthorized = user.emailAuthorized;
    const currentPage = useSelector(selectCandidateCurrentPage);
    const candidates = useSelector(selectProjectCandidates);
    const isRelevanceLoading = useSelector(
        checkIfLoading(getShowByRelevance.type)
    );
    const isCandidatesLoading = useSelector(
        checkIfLoading(getProjectCandidates.type)
    );
    const isCandidatesFiltersLoading = useSelector(
        checkIfLoading(getShowByProjectFilters.type)
    );
    const isShowByMaxLoading = useSelector(
        checkIfLoading(getShowByMaxExperience.type)
    );
    const isShowByMinLoading = useSelector(
        checkIfLoading(getShowByMinExperience.type)
    );
    const isShowByPersonalEmailLoading = useSelector(
        checkIfLoading(getShowByPersonalEmails.type)
    );
    const totalCandidates = useSelector<any, number>((state) =>
        get(state, "project.totalCandidates")
    );

    const [pageSize, setPageSize] = useState<number>(50);

    const handlePageChange = (page: number, pageSize: number) => {
        dispatch(setCandidatePage(page));
        setPageSize(pageSize);
        dispatch(
            getProjectCandidates({
                projectId: params.id,
                start: page,
                limit: pageSize,
                action: getProjectCandidates.type,
            })
        );
        if (wrapperRef.current) wrapperRef.current.scrollTop = 0;
    };

    const candidatesList = searchText
        ? candidates.filter((item: ICandidate) =>
              item.name.toLowerCase().includes(searchText.toLowerCase())
          )
        : candidates;

    return (
        <>
            {isRelevanceLoading ||
            isCandidatesLoading ||
            isShowByMinLoading ||
            isShowByMaxLoading ||
            isShowByPersonalEmailLoading ||
            isCandidatesFiltersLoading ? (
                <div className={style["sp__relevanceLoader"]}>
                    <Spin />
                </div>
            ) : (
                <div
                    ref={wrapperRef}
                    className={style["sp__item"]}
                    style={{
                        height: selectAllCheck
                            ? emailAuthorized
                                ? "calc(100vh - 348px)"
                                : "calc(100vh - 382px)"
                            : emailAuthorized
                            ? "calc(100vh - 348px)"
                            : "calc(100vh - 382px)",
                    }}
                >
                    {candidatesList && candidatesList.length ? (
                        activeView === "list" ? (
                            candidatesList.map((val: ICandidate, i: number) => {
                                return (
                                    <ProjectCard
                                        key={val._id}
                                        index={i}
                                        data={val}
                                    />
                                );
                            })
                        ) : (
                            <GridView view={activeView} data={candidatesList} />
                        )
                    ) : (
                        <div className={style["sp__relevanceLoader"]}>
                            <p>No candidates found.</p>
                        </div>
                    )}
                </div>
            )}
            {isRelevanceLoading ||
            isCandidatesLoading ||
            isShowByMinLoading ||
            isShowByMaxLoading ||
            isCandidatesFiltersLoading ||
            isShowByPersonalEmailLoading ||
            isSample ? null : (
                <Pagination
                    size="small"
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalCandidates}
                    onChange={handlePageChange}
                    showSizeChanger={true}
                    showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`
                    }
                    style={{
                        textAlign: "right",
                        paddingTop: 10,
                        fontSize: 12,
                    }}
                />
            )}
        </>
    );
}
