import React, { useRef, useState } from "react";
import Portal from "@mui/base/Portal";
import { PopoverOrigin } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { Button as AntdButton } from "antd";
import { useSelector } from "react-redux";

import { selectAllProjects } from "../../pages/allProjects/index.reducer";
import handleCleverTap from "../../utils/clevertap";
import style from "../Navbar/index.module.scss";
import Nudge from "../Nudges/Nudges";
import { selectInstallExtensionNudge } from "../Nudges/Nudges.reducer";

type SourceCandidatesMenuType = {
    btnText: string;
    children: JSX.Element[] | JSX.Element;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    buttonStyle?: React.CSSProperties;
};

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
    ({ transformOrigin }) => ({
        "& .MuiPaper-root": {
            marginTop:
                transformOrigin?.vertical === "bottom" ? "-0.5rem" : "0.5rem",
            borderRadius: "1rem",
            fontFamily: "Poppins",
            "& .MuiMenuItem-root": {
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: 600,
            },
        },
    })
);

export default function SourceCandidatesMenu({
    btnText,
    children,
    anchorOrigin,
    transformOrigin,
    buttonStyle,
}: SourceCandidatesMenuType) {
    const projects = useSelector(selectAllProjects);
    const installExtensionNudge = useSelector(selectInstallExtensionNudge);

    let elements: React.ReactElement[] = [];
    const container = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    React.Children.forEach(
        children,
        (child: React.ReactElement<{ onClick: () => void }>, index) => {
            if (!React.isValidElement(child)) return;

            const { onClick } = child.props;

            elements.push(
                React.cloneElement(child, {
                    key: index,
                    onClick: () => {
                        onClick();
                        handleClose();
                    },
                })
            );
        }
    );

    const onClickToggle = (e: any) => {
        handleCleverTap(btnText);
        setAnchorEl(e.currentTarget);
    };

    return (
        <>
            <div ref={container} style={{ position: "relative" }}>
                <AntdButton
                    className={style["nav__prodWalk"]}
                    onClick={(e) => onClickToggle(e)}
                    style={buttonStyle}
                >
                    {btnText}
                </AntdButton>
                <StyledMenu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                >
                    {elements}
                </StyledMenu>
            </div>
            {btnText === "Source Candidates" &&
                projects.length === 1 &&
                !installExtensionNudge && (
                    <Portal container={container.current}>
                        <Nudge
                            variant="top"
                            type="installExtension"
                            text={"Wait no more, search for candidates now 🚀"}
                            wrapperStyle={{
                                position: "absolute",
                                top: "120%",
                                left: "30%",
                                transform: "translateX(-50%)",
                                minWidth: 250,
                                textAlign: "right",
                                cursor: "default",
                            }}
                            arrowStyle={{
                                margin: "0.75rem 2rem 0",
                            }}
                        />
                    </Portal>
                )}
        </>
    );
}
