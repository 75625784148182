import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, Stack, Typography } from "@mui/material";
import { Typography as AntDTypography, Input, Modal } from "antd";

import code from "../../assets/img/code-bg.png";
import codeLogo from "../../assets/img/hq-code-logo.png";
import refer from "../../assets/img/refer.png";
import "./ReferralButton.scss";
import { useDispatch, useSelector } from "react-redux";

import useCopyToClipboard from "../../hooks/useCopyToClipboard.hook";
import {
    selectUser,
    sendReferralCode,
    setSentInvite,
} from "../../pages/Signin/Signin.reducer";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";

const { Title } = AntDTypography;

type IReferralButtonProps = {
    showReferralModal: boolean;
    setShowReferralModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ReferralButton({
    showReferralModal,
    setShowReferralModal,
}: IReferralButtonProps) {
    const user = useSelector(selectUser);
    const referralCode = user.referralCode;
    const [, copy] = useCopyToClipboard();
    const message = `Hey, I discovered an amazing sourcing tool, EasySource! It automates candidate sourcing & outreach. I'm loving it! Use my referral code  for a special offer. Visit bit.ly/42fpo8y to explore. You won't regret it!`;
    return (
        <>
            {/* <Button
                variant="contained"
                onClick={() => setShowReferralModal(true)}
                style={{
                    textTransform: "none",
                    backgroundColor: "#008095",
                    color: "#fff",
                    flex: 1,
                    margin: "1rem 0",
                }}
            >
                Refer Someone!
                <br /> (Get 20 additional credits)
            </Button> */}
            <Modal
                centered
                open={showReferralModal}
                footer={null}
                // bodyStyle={{ backgroundColor: "#fff" }}
                className="referralModal"
                width={1000}
                destroyOnClose={true}
                closable={true}
                onCancel={() => setShowReferralModal(false)}
                zIndex={1}
            >
                <Stack direction="row">
                    <Stack
                        p={12}
                        style={{
                            backgroundColor: "#008095",
                            borderBottomLeftRadius: "2rem",
                            borderTopLeftRadius: "2rem",
                        }}
                        spacing={1}
                    >
                        <img src={refer} alt="refer" />
                        <Typography align="center" color="#fff" variant="h4">
                            Find Right Talent, <br /> Right Now
                        </Typography>
                    </Stack>
                    <Stack
                        p={5}
                        spacing={3}
                        style={{
                            backgroundColor: "#fff",
                            borderBottomRightRadius: "2rem",
                            borderTopRightRadius: "2rem",
                        }}
                    >
                        <Typography
                            color="#344055"
                            variant="h4"
                            style={{ fontSize: "30px" }}
                        >
                            Refer a colleague or a friend!
                        </Typography>
                        <Typography
                            color="#B6B8C3"
                            variant="body2"
                            style={{
                                fontSize: "18px",
                                fontStyle: "italic",
                                fontWeight: "bold",
                            }}
                        >
                            Refer a friend and get 10 free credits!
                            <br />
                            They also get 10 free credits because of you
                        </Typography>
                        <div className="referralCodeContainer">
                            <img
                                src={code}
                                alt="code"
                                className="referralCodeGridBackground"
                            />
                            <div className="referralCodeLogo">
                                <img alt="code logo" src={codeLogo} />
                            </div>
                            <div className="referralCodeContent">
                                <Stack>
                                    <Typography color="#000" variant="h6">
                                        {referralCode || "Code Unavailable"}
                                    </Typography>
                                    <Typography color="#000" variant="body2">
                                        Referral code
                                    </Typography>
                                </Stack>
                                {/* <Typography
                                    color="#B6B8C3"
                                    variant="body2"
                                    my={1}
                                >
                                    Valid until 12 February 2024
                                </Typography> */}
                            </div>
                        </div>
                        <Button
                            variant="contained"
                            startIcon={<ContentCopyIcon fontSize="small" />}
                            onClick={() => copy(message)}
                            style={{
                                width: "fit-content",
                                alignSelf: "center",
                                backgroundColor: "#008095",
                            }}
                        >
                            Copy Referral Text
                        </Button>
                        <Typography
                            color="#344055"
                            variant="h4"
                            my={2}
                            style={{ fontSize: "20px", alignSelf: "center" }}
                        >
                            OR
                        </Typography>
                        <ReferralForm />
                    </Stack>
                </Stack>
            </Modal>
        </>
    );
}

function ReferralForm() {
    const dispatch = useDispatch();
    const isLoading = useSelector(checkIfLoading(sendReferralCode.type));
    const user = useSelector(selectUser);
    const inviteStatus = user.sentInvite;

    const [email, setEmail] = useState("");

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(sendReferralCode({ email, action: sendReferralCode.type }));
        setEmail("");
    };

    const openSuccessModal = inviteStatus === "SENT";
    const openAlreadyInvitedModal = inviteStatus === "ALREADY_INVITED";

    return (
        <>
            <form className="referralFormContainer" onSubmit={handleSubmit}>
                <Title level={5}>Send Referral Email</Title>
                <div className="referralFormInputsContainer">
                    <Input
                        required={true}
                        value={email}
                        placeholder="Enter Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        disabled={isLoading}
                        variant="contained"
                        type="submit"
                        style={{
                            width: "fit-content",
                            alignSelf: "center",
                            backgroundColor: "#008095",
                        }}
                    >
                        {isLoading ? "Loading..." : "Send Referral"}
                    </Button>
                </div>
            </form>
            {openSuccessModal && (
                <MessageModal
                    open={openSuccessModal}
                    messageText="Thanks for referral"
                    messageTitle="Remind your colleague to check their email!"
                    successBtnText="Refer More friends"
                />
            )}
            {openAlreadyInvitedModal && (
                <MessageModal
                    open={openAlreadyInvitedModal}
                    messageTitle="Oops...this person is already with us!"
                    messageText="Refer someone else and earn additional credits!"
                    successBtnText="Try Again"
                />
            )}
        </>
    );
}

type IMessageModal = {
    open: boolean;
    messageTitle: string;
    messageText: string;
    successBtnText: string;
};

function MessageModal({
    open,
    messageText,
    messageTitle,
    successBtnText,
}: IMessageModal) {
    const [show, setShowModal] = useState(open);
    const dispatch = useDispatch();

    const handleClose = () => {
        setShowModal(false);
        dispatch(setSentInvite("NOT_SENT"));
    };

    return (
        <Modal
            centered
            open={show}
            className="referralModal"
            onOk={handleClose}
            onCancel={handleClose}
            zIndex={2}
            destroyOnClose={true}
            closable={true}
            footer={false}
        >
            <Stack
                style={{ backgroundColor: "#FFFFFF" }}
                spacing={3}
                alignItems="center"
                p={8}
            >
                <Stack
                    spacing={1}
                    style={{
                        borderBottomLeftRadius: "1rem",
                        borderTopLeftRadius: "1rem",
                    }}
                >
                    <Typography
                        align="center"
                        color="#001246"
                        variant="h4"
                        fontWeight={600}
                    >
                        {messageTitle}
                    </Typography>
                    <Typography
                        align="center"
                        color="#001246"
                        variant="body2"
                        fontWeight={600}
                        fontSize={16}
                    >
                        {messageText}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} my={8} width="100%">
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        style={{
                            backgroundColor: "#E3E3E3",
                            alignSelf: "center",
                            color: "#7D7D7D",
                            flex: 1,
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "#008095",
                            alignSelf: "center",
                            textTransform: "none",
                            flex: 1,
                        }}
                        onClick={handleClose}
                    >
                        {successBtnText}
                    </Button>
                </Stack>
            </Stack>
        </Modal>
    );
}
